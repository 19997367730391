.WarningInfoIconContainer{
  width: 18px !important;
  height: 18px !important;
  background-color: #45946B;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  margin-left: 8px;
  cursor: pointer;
}


.WarningInfoIcon{
  fill: currentColor;
  height: 100%;
}

.BaseHeaderCell-action{
  border: none;
  background-color: transparent;
  padding: 0;
}
