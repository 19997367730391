.TerminalProfiles{
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media (max-width: 710px) {
      flex-wrap:wrap;
    }
  }
  &-toolbar{
    padding: 8px;
    max-width: 700px;
    button{
      margin-left: 0!important;
    }
  }
  &-label{
    width: 100px;
  }
  &-gridDropdown{
    width: 600px;
    @media (max-width: 710px) {
      width: 300px;
    }
  }
}
