@import "../../styles/config.scss";

.date-range-wrapper {
  display: flex;
  padding: -1px;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  .k-datepicker {
    height: 32px;
    width: 100%;
  }
  .k-dateinput-wrap .k-input {
    height: 32px;
  }
  .ant-select {
    border-radius: 0;
    min-width: 200px;
    z-index: 2;
    .ant-select-selection {
      border-radius: 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
  .ant-select-arrow {
    margin-top: -5px;
    right: 7px;
  }
  .date-ranger {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    &.start {
      width: 60%;
      .k-picker-wrap {
        border-left: none;
        border-right: none;
        border-radius: 0;
        width: 100%;
      }
    }
    &.end {
      width: 40%;
    }
    p {
      min-width: 4.5rem;
      margin-bottom: 0;
      text-align: right;
      margin-right: 10px;
    }
  }
  &.popup {
    width: 100%;
    flex-direction: column;
    .date-ranger {
      width: 100%;
      &.start {
        .k-picker-wrap {
          border-left: 1px solid rgba(0, 0, 0, 0.08);
        }
        flex-direction: column;
        .ant-select {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      &.end {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}

.k-calendar-container {
  z-index: 9999 !important;
}

@media (max-width: 1650px) {
  .k-dateinput-wrap .k-input {
    font-size: 13px;
  }
  .popup-owners-grid-wrapper {
    .k-button {
      &.owner-btn-toggler {
        width: 100%;
      }
    }
  }
  .date-range-wrapper {
    .date-ranger {
      &.start {
        width: 60%;
      }

      &.end {
        width: 40%;
      }
    }

    &.popup {
      width: 100%;
      flex-direction: column;
      .date-ranger {
        width: 100%;
        &.start {
          .k-picker-wrap {
            border-left: 1px solid rgba(0, 0, 0, 0.08);
          }
          flex-direction: column;
          .ant-select {
            width: 100%;
            margin-bottom: 10px;
          }
        }
        &.end {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .date-range-wrapper {
    &:not(.popup) {
      .date-ranger {
        &.start {
          width: 70%;
        }
        &.end {
          width: 30%;
        }
      }
    }
  }
}

@media (max-width: $resp_lg) {
  // global enlarging the calendar button
  .k-datepicker .k-select, .k-timepicker .k-select, .k-datetimepicker .k-select {
    width: 50px !important;
  }
  .date-range-wrapper {
    margin-top: 10px;
    .date-ranger {
      width: 100%;
      .k-datepicker {
        height: 39px;
      }
      .k-dateinput-wrap {
        .k-input {
          padding: 19px 12px;
          font-size: 16px;
        }
      }
    }
  }
  .date-range-wrapper {
    &:not(.popup) {
      .date-ranger {
        &.start {
          width: 100%;
        }
        &.end {
          width: 100%;
        }
      }
    }
    flex-direction: column;
    .date-ranger {
      box-sizing: border-box;
      &.start { 
        width: 100%;
        flex-direction: column;
        .ant-select {
          width: 100%;
        }
        .k-datepicker {
          margin-top: 10px;
          border-left: 1px solid rgba(0, 0, 0, 0.08);
          border-right: 1px solid rgba(0, 0, 0, 0.08);
        }
      }
      &.end {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: $resp_sm) {

}
