.ModalAddCommissions{
  width: 900px!important;
  &-container{
    justify-content: space-between;
    margin-bottom: 16px;
  }
  &-card{
    width: 99%;
    @media (max-width: 992px) {
      margin-bottom: 16px;
    }
  }
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  &-input{
    width: 100%;
  }
  &-globalError{
    color: red;
    margin-bottom: 16px;
  }

  .GridDropdown-title{
    margin: 0!important;
  }

  .GridDropdown-contentWrapper{
    top: -480px;
    left: 0;
  }
}

.ModalAddCommissions-selectRow{
  display: flex;
  align-items: center;
}

.ModalAddCommissions-label{
  width: 100px
}


.ModalAddCommissions-select{
  width: 456px;
  margin-top: 8px;

  @media (max-width: 640px){
    width: 100%;
  }
}
