.dropdown-owner {
  &.city {
    margin-left: 10px;
    .k-grid-header-wrap {
      thead {
        tr {
          &.k-filter-row {
            display: table-row !important;
          }
        }
      }
    }
  }
  .k-grid-toolbar {
    padding: 0;
  }
}
.popup-owners-grid-wrapper {
  &.dropdown-cities {
    margin-left: 10px;
    min-width: 220px;
  }
}

.popup-owners-grid {
  min-width: 350px;
  width: auto;
  height: 100%;
  min-height: 350px;
  max-height: 400px;
  position: absolute;
  left: 0;
  top: 32px;
  z-index: 99;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.08);
  // border: none;
  background-color: #fff;
  display: none;
  outline: none !important;
  .k-grid {
    max-height: 400px;
  }
  &.open {
    display: block;
  }
}

.outline-subwrapper {
  outline: none !important;
  position: fixed;
  border: 1px solid rgba(0, 0, 0, 0.08);
  // .k-grid-virtual {
  //   .k-grid-content {
  //     .k-grid-table {
  //       float: none !important;
  //     }
  //   }
  // }
}

.popup-owners-grid-wrapper {
  position: relative;
  outline: none !important;
  margin-left: 0;
  height: 32px;
  button {
    &.owner-btn-toggler {
      width: 100%;
      margin-left: 0;
      height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        display: block;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
      }
    }
  }
  .owner-top-handlers {
    margin-left: 0.6em;
  }
}
.newTerminal---additional-media-input-class {
  margin-left: 10px;
}
.owner-popup-footer {
  position: relative;
  width: 100%;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.08);
  flex-wrap: wrap;
  background-color: #f6f6f6;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  .btn-group {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 0.6rem;
    }
  }
}

.owner-popup-header {
  position: relative;
  margin: 0.6rem 0;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  .owner-popup-search {
    position: relative;
    display: flex;
    flex-grow: 1;
  .owner-popup-search-input {
    display: flex;
    flex-grow: 1;
    height: 32px;
  }
  button {
    margin-left: 0.6rem;
    &:first-child {
      margin-left: 0.6rem;
    }
  }
}
}

.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none !important;
  z-index: 21;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  &.open {
    display: block;
  }
}

@media (max-width: 992px) {
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .dropdown-owner {
    &.k-grid {
      table {
        empty-cells: hide !important;
      }
      // скрываем тайтлы колонок
      .k-grid-header {
        display: table-row !important;
      }
    }
  }
  .popup-owners-grid {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    max-height: 100%;
    background: transparent;
    min-width: 100%;
    min-height: 100%;
    border: none;
    .owner-popup-header {
      margin: 0;
      padding: 0.6rem;
    }
    .k-grid {
      border: none;
      height: 100% !important;
      // страшнейший костыль , пока не найдётся адекватный способ
      // создания React Kendo Grid Footer
      // создано для того чтобы кастомный футер был видим
      max-height: 100%;
    }

    //костыльный вариант убирания юзелесс столбиков для моб. отображения
    .mobile-hidden {
      display: none;
    }
    .k-grid table {
    }
    .k-grid-header-wrap {
      thead {
        tr {
          &:last-child {
            display: table-row !important;
          }
        }
      }
    }
    .outline-subwrapper {
      height: 100%;
      position: relative;
      display: block;
      overflow: hidden;
      border: none;
    }
    .owner-popup-header {
      button {
        margin-left: 0;
      }
    }
    .owner-top-handlers {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      button {
        width: 50%;
      }
    }
  }
  // .owner-popup-footer {
  //   display: none;
  // }
  .k-header {
    .k-grid-toolbar {
      margin-left: 0;
      .owner-popup-footer {
        display: flow-root;
        margin-left: 0;
        width: 100%;
        background-color: transparent;
        border: none;
        .ant-btn-group {
          margin-top: 5px;
        }
      }
    }
  }
  .popup-owners-grid-wrapper {
    margin-top: 10px;
    height: auto;
    .k-button {
      &.owner-btn-toggler {
        width: 100%;
      }
    }
  }
  // START new mobile mode
  .dropdown-owner {
    &.mobileMode {
      table,
      td,
      tbody,
      tr {
        display: block;
      }
      table {
        width: 100% !important;
      }
      tr {
        &.k-master-row {
          display: flex;
          align-items: center;
          td {
            height: 100%;
            display: flex;
            align-items: center;
            border: none;
            &:nth-child(2) {
              width: 50px;
            }
            &:last-child {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-family: Tahoma, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto,Oxygen, Ubuntu, Cantarell,
              "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
              font-feature-settings:"tnum", "tnum";
            }
          }
        }
      }

      .owner-popup-header {
        padding: 0;
      }

      .owner-popup-footer {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        .owner-popup-filter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .owner-popup--top-checkboxes {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .ant-btn-group {
          margin: 10px 0;
          width: 100%;
          button {
            margin-left: 0;
            width: 50%;
          }
        }
      }
      .owner-popup-search {
        position: relative;
        width: 100%;
        .owner-popup-search-input {
          width: 100%;
        }
        button {
          &.btn-clean {
            position: absolute;
            right: 0;
            top: 0;
            color: #656565;
          }
        }
      }
    }
  }
  //END new mobile mode
}

@media(max-width:430px) {
  .popup-owners-grid-wrapper {
    &.dropdown-cities {
      margin-left: 0;
      min-width: 100%;
    }
  }
  .dropdown-owner.city {
    margin-left: 0;
  }
  .newTerminal---additional-media-input-class {
    width: 100% !important;
    margin-left: 0;
  }
}

@media (max-width: 340px) {
  .owner-popup-footer {
    flex-direction: column;
    .btn-group {
      padding: 10px 0;
    }
    .owner-popup-filter,
    .btn-group {
      width: 100%;
    }
  }
}
