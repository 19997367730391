.modal--add-limit {
    margin-top: 10px;
}

.ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: none;
    button {
        width: 48%;
        &.modal--add-limit-cancel {
            order: 2;
        }
    }
}

.k-numerictextbox {
    width: 60%;
}

.popup-textbox-wrapper {
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
        padding-right: 10px;
    }

    &.last {
        justify-content: flex-end;
    }
}

@media (max-width: 767px) {
    .popup-textbox-wrapper {
        width: 100%;
        &.last {
            justify-content: flex-start;
            margin-top: 10px;
        }
        p {
            min-width: 70px;
        }
    }
    .k-numerictextbox {
        width: 100%;
    }
}