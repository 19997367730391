.MenuAuditGrid-dateRow{
  display: flex;
  align-items: center;

  &>div{
    &:first-child{
      margin-right: 24px;
    }
  }
}

.MenuAuditGrid-label{
  width: 64px;
}

.MenuAuditGrid-select{
  width: 500px;
  margin-bottom: 16px;

  @media (max-width: 560px) {
    width: 100%;
  }
}

.MenuAuditGrid-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &>button{
    margin-left: 24px;

    @media (max-width: 638px) {
      margin: 16px 0;
    }
  }
}

.MenuAuditGrid-error{
  color: red;
  margin-top: 8px;
}
