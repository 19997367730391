.PartnerInfoSenderGrid-toolbar{
  display: flex;
  margin: -8px;
  flex-wrap: wrap;

  &>div{
    padding: 8px;
  }
}

.PartnerInfoSenderGrid-rowCustomise{
  background-color: lightgreen;
}


.PartnerInfoSenderCustomiseDialog-content{
  text-align: center;
}

.EditingOwnersModel-inputRow{
  display: flex;
  align-items: center;
  margin-bottom: 22px;


  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.EditingOwnersModel-label{
  flex: 0 0 150px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;

  @media (max-width: 520px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}

.EditingOwnersModel-input{
  flex: 1 1 auto;

  @media (max-width: 520px) {
    width: 100%;
  }
}

.EditingOwnersModel{
  width: 720px !important;

  @media (max-width: 764px) {
    .EditingOwnersModel{
      width: 100% !important;
    }
  }
}

.EditingOwnersModel-error{
  color: red;
  text-align: center;
}
