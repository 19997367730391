.TerminalsActiveServicesGrid-toolbar{
  display: flex;
  align-items: center;

  .GridDropdown-contentWrapper{
    left: 0;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.TerminalsActiveServicesGrid-fields{
  display: flex;
  align-items: center;
  margin: -4px -12px;
  flex-wrap: wrap;

  @media (max-width: 1680px) {
    flex: 0 0 840px;
    width: 840px;
  }

  @media (max-width: 1080px) {
    flex: 0 0 500px;
    width: 500px;
  }

  @media (max-width: 640px) {
    flex: 0 0 100%;
    width: 100%;
    flex-direction: column;
    margin: -4px 0;
  }
}

.TerminalsActiveServicesGrid-fieldRow{
  display: flex;
  align-items: center;
  padding: 4px 12px;

  @media (max-width: 640px) {
    flex: 1 1 100%;
    width: 100%;
    padding: 4px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

.TerminalsActiveServicesGrid-label{
  width: 60px;
  flex: 0 0 60px;

  @media (max-width: 640px) {
    width: auto;
    flex: 1 1 auto;
  }
}

.TerminalsActiveServicesGrid-select{
  width: 420px;
  flex: 0 0 420px;

  @media (max-width: 640px) {
    flex: 1 1 100%;
    width: 100%;
  }
}

.TerminalsActiveServicesGrid-nativeSelect{
  width: 240px;
  flex: 0 0 240px;

  &>div{
    width: 100%;
  }

  @media (max-width: 1080px) {
    width: 420px;
    flex: 0 0 420px;
  }

  @media (max-width: 640px) {
    flex: 1 1 100%;
    width: 100%;
  }
}

.TerminalsActiveServicesGrid-action{
  padding-left: 16px;

  @media (max-width: 640px) {
    padding-left: 0;
    margin-top: 16px;
  }
}
