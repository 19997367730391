.k-grid tr {
  white-space: nowrap;
}

.k-grid-content th, .k-grid-content td {
  padding: 0 12px !important;
  font-size: 12px;
  font-family: Tahoma;
}

// Start important rule for fixing virtual scroll bug
.k-grid-virtual .k-grid-content > .k-height-container {
  float: none !important;
}

.groupTr {
  background-color: #dddddd;

  td {
    padding: 0 !important;
  }

   .groupWrapper{
    padding:  0 20px;
    width: 100%;
    background-color:#dddddd;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:30px;

    .groupTitle {
      font-weight: 700;
     }
   }
 
}

$max-order: 30;

// Цикл для генерації стилів для кожного порядку
@for $i from 0 through $max-order {
  .groupRowOrder#{$i} {
    // Тут вказуються стилі для конкретного порядку
    // Наприклад, зміна відступу залежно від порядку
   td { 
    padding-left: $i * 40px !important;
    background-color:#dddddd !important;

  }
  }
}