.SupplierCommissions{
  &-container{
    max-width: 800px;
    position: relative;
    padding: 16px 5px 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    margin: 0 16px;
  }
  &-toolbar{
    padding: 16px;
    max-width: 800px;
  }
  &-header{
    margin-bottom: 16px;
  }
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media (max-width: 769px) {
      flex-wrap: wrap;
    }
  }
  &-label{
    width: 130px;
  }
  &-input{
    width: 500px;
  }
  &-btn{
    margin-left: auto;
    @media (max-width: 769px) {
      margin-left: 0;
    }
  }
}
.align-self-start{
  align-self: flex-start;
}
