.PaymentTypeNBUCodeGrid{
  display: flex;
  padding: 8px;
}

.AddCodeModal{
  top: 20px !important;
}

.AddCodeModal-label {
  margin-bottom: 4px;
}

.AddCodeModal-selectWrapper{
  position: relative;
  margin-bottom: 8px;

  .GridDropdown-content{
    height: 396px;
    width: 496px;
  }
}

.AddCodeModal-error {
  color: red;
}
