.createApplication--row {
  margin-top: 20px;
  flex-wrap: wrap;
  &.space--between {
    justify-content: space-between;
  }
  .ant-input-number {
    width: 100%;
  }
}

.createApplication-elem-label {
  width: 100%;
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
}

.createApplication-elem-required {
  font-weight: bold;
  color: red;
}
