.RolesGrid{
  .RolesGrid--touchedRow > td{
    background-color: rgba(255,165,0,0.5) !important;
  }

  tr.RolesGrid--touchedRow.k-state-selected > td {
    background-color: rgba(255,165,0,0.5) !important;
  }
}

.RolesGrid-warning{
  margin-top: 8px;
}

.RolesGrid-errorTitle{
  font-size: 14px;
  color: red;
}

.RolesGrid-warningTitle {
  font-size: 16px;
  color: red;
  font-weight: 700;
  margin-bottom: 8px;
}

.RolesGrid-highlightedText {
  font-weight: 700;
}

.RolesGrid-toolbar {
  @media (max-width: 920px) {
    margin-top: 8px;
  }
}

