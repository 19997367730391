.ModalManagementPaymentTypes {
  width: 650px !important;

  &-row {
    display: flex;
    margin-bottom: 24px;
    @media (max-width: 645px) {
      flex-wrap: wrap;
    }
  }

  &-label {
    width: 120px;
    &--checkbox{
      width: 200px;
    }
  }
  &-checkbox{
    position: relative;
    min-width: 225px;
    .error-msg{
      left: 0;
    }
  }
  &-radio{
    display: inline-block;
    width: 90px;
    span.ant-radio{
      margin-right: 6px;
    }
  }
  &-dropDown {
    position: relative;
    width: 100%;
    .GridDropdown-title {
      width: 520px;
      margin-left: 0;
      @media (max-width: 645px) {
        width: 100%;
      }
    }
  }
}
.error-msg{
  position: absolute;
  top: 100%;
  left: 5px;
  color: red;
}
.relative{
  position: relative;
}
.red{
  border: 1px solid red;
}
