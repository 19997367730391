.ServiceBigTable-actions{
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  & button{
    margin: 0;
  }

  &>div{
    padding: 4px;
  }
}

.UpdateServiceStatusModal-content{
  text-align: center;
}

.CloneServiceModal-input{
  margin-bottom: 16px;
}

.CloneServiceModal{
  .ant-card-head-title{
    text-overflow: initial;
    white-space: initial;
  }

  ul{
    list-style: initial;
  }
}

.CloneServiceModal-error{
  color: red;
  text-align: center;
}

.CloneServiceModal-successfullyClonedWrapper{
  display: flex;
  justify-content: center;

  &>div{
    overflow: auto;
  }
}

.CloneServiceModal-successfullyClonedLatterMessage{
  margin-bottom: 8px;
}

.StepServiceCreationModal{
  width: 640px !important;

  .k-grid-header-wrap {
    thead {
      .k-checkbox {
        display: none;
      }
    }
  }
}
