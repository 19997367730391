.modalGraphicWH--sub-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

@media (max-width: 991px) {
  .modalGraphicWH-popup-cls {
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
