.TransactionsWithCashbackGrid-toolbar{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -8px -16px;

  &>div{
    padding: 8px 16px;
  }
}

.TransactionsWithCashbackGrid-dateRow{
  display: flex;
  align-items: center;
  margin: -8px;

  @media (max-width: 540px) {
    flex-direction: column;
  }
}


.TransactionsWithCashbackGrid-dateFiledRow{
  display: flex;
  align-items: center;
  padding: 8px;
}

.TransactionsWithCashbackGrid-label{
  flex: 0 0 64px;
  width: 64px;
}

.TransactionsWithCashbackGrid-datePicker{
  flex: 0 0 auto;
  max-width: initial;
  width: auto;
}

.TransactionsWithCashbackGrid-error{
  padding-top: 0 !important;
  margin-top: -8px;
  color: red;
  flex: 0 0 100%;
}
