.AuditSite-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.AuditSite-dateRow {
  display: flex;
  margin: 8px -16px;
  align-items: center;

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.AuditSite-dateFieldRow{
  display: flex;
  padding: 0 16px;
  align-items: center;

  @media (max-width: 540px) {
    &:first-of-type{
      margin-bottom: 6px;
    }
  }
}

.AuditSite-dateLabel {
  flex: 0 0 60px;
  width: 60px;
}

.AuditSite-errorMessage{
  width: 100%;
  flex: 0 0 100%;
  color: red;
  margin-top: 8px;
}
