.modal-add-agent {
  margin-top: 20px;
  .ant-modal-footer {
    padding: 10px 12px;
  }
  .createApplication--wrapper {
    padding: 0;
  }

  .createApplication--subwrapper {
    background-color: transparent;
    border: none;
  }
}

@media (min-width: 1200px) {
  .modal-add-agent {
    min-width: 960px;
  }
}

@media (max-width: 1200px) {
  .modal-add-agent {
    width: 100%;
  }
}