.TerminalRegistrationManagementModal{
  width: 600px !important;

  @media(max-width: 640px){
    .ant-modal-footer{
      flex-direction: column;
      align-items: center;

      button{
        width: 100%;

        &:last-of-type{
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }
}

.TerminalRegistrationManagementModal-alert{
  margin-bottom: 16px;
}
