.NewSchedule {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 560px) {
    align-items: start;
  }
}

.NewSchedule-radio {
  margin: 0 -12px;

  @media (max-width: 560px) {
    margin: 0;
    display: flex;
    flex-direction: column;
  }
}

.NewSchedule-radioItem {
  padding: 0 12px;

  input{
    margin-right: 4px;
  }

  @media (max-width: 560px) {
    padding: 0px;
  }
}

.NewSchedule-weekSchedule{
  width: 420px;
  margin-top: 16px;
}

.NewSchedule-divider{
  text-align: center;
}

.NewSchedule-action{
  margin-top: 16px;
}

.NewSchedule-error{
  color: red;
  margin-top: 8px;
}
