.TechnicalPartnersGrid {
  width: 600px !important;
  top: 10px !important;

  .TechnicalPartnersGrid-settlementTypeSelect .GridDropdown {
    z-index: 24;

    @media (min-width: 993px) {
      z-index: 24;
    }
  }

  @media (min-width: 993px) {
    .GridDropdown-contentWrapper {
      left: 0;
    }

    .GridDropdown-content {
      height: 360px;
    }
  }
}

.TechnicalPartnersGrid-toolbar {
  display: flex;
  margin: -4px;
  flex-wrap: wrap;

  & > div {
    padding: 4px;
  }
}

.TechnicalPartnersGrid-inputRow {
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.TechnicalPartnersGrid-label {
  flex: 0 0 120px;
  box-sizing: border-box;
  text-align: left;

  @media (max-width: 520px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}

.TechnicalPartnersGrid-input {
  flex: 1 1 auto;

  @media (max-width: 520px) {
    width: 100%;
  }
}
