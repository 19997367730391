.CollectionHistoryGrid-toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    margin-top: 16px;
  }
}

.CollectionHistoryGrid-dateRow {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 8px;

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.CollectionHistoryGrid-ActionRow{
  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.CollectionHistoryGrid-error{
  padding-top: 4px;
  color: red;
}

.CollectionHistoryGrid-datePicker {
  //input {
  //  pointer-events: none
  //}

  @media (max-width: 576px) {
    width: 100% !important;
  }

}

.CollectionHistoryGrid-inputRow {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
