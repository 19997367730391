.ChangeDealerCommissions{
  &-selects{
    .GridDropdown-title{
      width: 495px;
      @media (max-width: 662px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  &-row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 16px;
  }
  &-label{
    width: 90px;
  }
  &-wrapp{
    @media (max-width: 662px) {
      width: 100%;
    }
  }
  &-datepicker{
    width: 495px!important;
    margin-left: 5px;
    @media (max-width: 662px) {
      width: 100% !important;
      margin-left: 0;
    }
  }
  &-btnGroup{
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px!important;
    button{
      margin: 0 8px 8px 0;
    }
  }

}



