.NavigationMenuPropGrid-modalButton{
  width: 360px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  text-align: left !important;
  margin: 0 !important;

  @media (max-width: 640px) {
    width: calc(100vw - 160px);
  }
}

.NavigationMenuPropGrid-selectProfileDialog{
  width: 96vw !important;
  top: 16px;
}

.NavigationMenuPropGrid-row{
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &>div{
    margin-right: 32px;
  }

  @media (max-width: 1300px) {
   flex-direction: column ;
    align-items: flex-start;
  }
}

.NavigationMenuPropGrid-fieldRow{
  &>div{
    margin-right: 0;
  }

  @media (max-width: 1300px) {
    flex-direction: row;
  }
}

.NavigationMenuPropGrid-textAreaRow{
  align-items: flex-start;

  & .NavigationMenuPropGrid-fieldRow:last-child{
    align-items: flex-start;

    & .NavigationMenuPropGrid-label{
      margin-top: 4px;
    }
  }
}

.NavigationMenuPropGrid-label{
  width: 112px;
}

.NavigationMenuPropGrid-selectProp{
  width: 360px;
  margin: 0;

  @media (max-width: 640px) {
    width: calc(100vw - 160px);
  }
}

.NavigationMenuPropGrid-propValueSelect{
  width: 360px;

  @media (max-width: 640px) {
    width: calc(100vw - 160px);
  }
}

.NavigationMenuPropGrid-textArea{
  width: 360px;

  @media (max-width: 640px) {
    width: calc(100vw - 160px);
  }
}

.NavigationMenuPropGrid-search{
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    flex-direction: column;
  }
}

.NavigationMenuPropGrid-searchAction{
  margin-top: 36px;

  @media (max-width: 640px) {
    margin-top: -16px;
  }
}

.NavigationMenuPropGrid-search{
  @media (max-width: 640px) {
    margin-bottom: 16px;
  }
}

.NavigationMenuPropGrid-definitedPropRow{
  background-color: lightgreen;
}

.NavigationMenuPropGrid-progress{
  text-align: center;
}

.NavigationMenuPropGrid-alert{
  margin-top: 16px;
}
