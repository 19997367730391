@import "../../styles/config.scss";

.basegrid-toolbar-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main-grid-toolbar-row {
  &.additionalToolbar {
    display: flex;
    align-items: center;
    //special styles for custom toolbar components
    .popup-owners-grid-wrapper .k-button.owner-btn-toggler,
    .date-range-wrapper {
      width: 85%;
    }
  }
}

@media (max-width: $resp_lg) {
  .basegrid-toolbar-btns {
    .ant-row-flex {
      .ant-btn-group {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: $resp_xl) {
  .only-lg-flex {
    display: flex;
  }
}

@media (max-width: $resp_md) {
  .k-grid-toolbar {
    .main-grid-toolbar-row {
      display: flex;
      width: 100%;
      .ant-col {
        width: 100%;
      }
    }
  }
  .main-grid-toolbar-row {
    flex-direction: column;
    .custom-toolbar--wrapper {
      order: 2;
    }
  }
  .base-toolbar--wrapper {
    display: flex;
    order: 1;
    .basegrid-toolbar-btns {
      width: 100%;
      .ant-row-flex {
        width: 100%;
        .ant-btn-group {
          width: 100%;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
  .main-grid-toolbar-row.additionalToolbar .popup-owners-grid-wrapper .k-button.owner-btn-toggler,
  .main-grid-toolbar-row.additionalToolbar .date-range-wrapper {
    width: 100%;
  }
}

@media (max-width: $resp_sm) {
  .transaction-custom-filter .ant-col div .ant-select,
  .transaction-custom-filter .ant-col div .ant-input {
    margin-top: 0 !important;
  }
  .main-grid-toolbar-row {
    &.additionalToolbar {
      .transaction-custom-filter {
        .ant-col {
          margin-top: 5px;
        }
      }
    }
  }
  .mob-space-between {
    justify-content: space-between;
  }
  .sm-p-0 {
    padding: 0 !important;
  }
}

@media (max-width: 400px) {
  .basegrid-top-toolbar {
    .basegrid-toolbar-btns {
      flex-wrap: wrap;
    }
    .grid-mode-toggler {
      margin-top: 10px;
    }
    button {
      &.btn-excel-export {
        margin-left: 5px;
      }
    }
  }
}

.basegrid-toolbar-remoteTerminals{
  margin-right: 8px;

  .ant-checkbox-wrapper{
    margin-left: 6px;
  }
}

.basegrid-toolbar-remoteTerminals--active{
  font-weight: 700;
}

.Toolbar-graphqlActions{
  margin-top: 16px;
  display: flex;
  align-items: center;

  @media (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
}

.Toolbar-graphqlActionsItem{
  align-items: center !important;
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  margin-right: 16px;

  &:last-child{
    margin-right: 0;
    button:last-child {
      margin: 0;
    }
  }

  @media (max-width: 840px) {
    margin-right: 0;
    margin-bottom: 0;
    justify-content: flex-start;
  }

  @media (max-width: 480px) {
    &:last-child{
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.Toolbar-select{
  width: 72px;
  margin-right: 16px;
}

.Toolbar-input{
  width: 48px;
  margin: 0 6px;

  @media (max-width: 480px) {
   margin: 8px 6px;
  }
}

.Toolbar-numberInput{
  width: 64px;
  margin: 0 6px;

  @media (max-width: 480px) {
    margin: 8px 6px;
  }
}

.RowBaseButtons{
  @media (max-width: 920px) {
    margin-bottom: 8px;
  }
}

.ColBaseButtons{
  margin: -4px -8px;
  @media (max-width: 920px) {
    margin: -4px -8px 4px;
  }

  .basegrid-toolbar-remoteTerminals{
    margin: 0;
  }

  &>div{
    padding: 4px 8px;
  }
}

.Toolbar-bottomPagePanel{
  padding: 4px 8px;
}

// base class wrapped exel
// search-form__button
