.EmailReceiptsModal-transactionInput{
  margin-bottom: 16px;
}

.EmailReceiptsModal-error{
  margin-top: 8px;
  color: red;
  font-size: 14px;
  text-align: center;
}
