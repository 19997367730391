.Commission{
  padding: 16px 8px;
  height: 100%;
  overflow: scroll;
}

.Commission-actionPanel{
  display: flex;
}

.Commission-actionPanel{
  margin-bottom: 8px;
}

.Commission-buttonWrapper{
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.Commission-content{
  background: #f0f2f5;
  padding: 8px;
}

.Commission-dropdown{
  margin-bottom: 32px;
}
