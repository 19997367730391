.CopyDealerCommissions-container{
  max-width: 767px;
  padding: 16px;
  margin: 0 auto;

  .ant-card-type-inner .ant-card-body{
    padding: 16px 16px!important;
  }
}
.CopyDealerCommissions-toolbar{
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.CopyDealerCommissions-header{
  padding: 16px 0;
}
.CopyDealerCommissions-row{
  display: flex;
  margin-bottom: 8px;
}
.CopyDealerCommissions-card-content{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.CopyDealerCommissions-card{
  margin-bottom: 16px;
  .GridDropdown-content{
    height: 38vh;
    @media (max-width: 992px) {
      height: auto;
    }
  }
  .CopyDealerCommissions-gridDropdown{
    .GridDropdown-contentWrapper{
      top: auto;
    }
    &--top{
      .GridDropdown-contentWrapper{
        bottom: 102%;
      }
    }
  }
}

.CopyDealerCommissions-footer{
  display: flex;
  justify-content: center;
}
.CopyDealerCommissions-label{
  input[type='radio']{
    margin-right: 8px;
  }
}
.CopyDealerCommissions-datepicker-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.CopyDealerCommissions-datepicker{
  margin-left: 5px;
}
.CopyDealerCommissions-title{
  margin-bottom: 16px;
}
