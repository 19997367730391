.modal-system-encashment {
  .modal-system-encashment--select {
    width: 100%;
    z-index: 9999991;
  }
}

@media (max-width: 991px) {
  .modal-change-profile-row {
    display: flex;
    flex-direction: column;
  }
  .modal-system-encashment {
    .modal-system-encashment--select {
      margin-top: 10px;
    }
  }
}
