.aap-change-radio {
  width: 100%;
  padding: 20px;
  .ant-radio-wrapper {
    display: flex;
    height: 40px;
    line-height: 40px;
    width: 100%;
    // margin-top: 30px;
    // justify-content: space-between;
  }
  .ant-radio {
    margin-top: 3px;
    input[type="radio"] {
      transform: scale(1.8);
    }
  }
}

// styles for disabling

.interval-col-dis {
  &.disabled {
    pointer-events: none;
    opacity: .4;
  }
}

.comission-radio-block {
  margin-top: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #666666;
  padding: 10px;

  &:first-child {
    margin-top: 0;
  }
  p {
    &.header-text {
      margin-left: 10px;
      font-size: 30px;
    }
  }
}

.interval-inputNumber {
  width: 100%;
  max-width: 100px;
  &.errorInputNumber {
    border-color: red;
    border-right-width: 1px !important;
  }
}

.fixedComission--select {
  margin-left: 5px;
  max-width: 70px;
  width: 100%;
}

.fixedComission-wrapper {
  width: 100%;
  &.disabled {
    pointer-events: none;
    opacity: .4;
  }
  
}

.interval-comission {
  margin-top: 30px;
  &.disabled {
    pointer-events: none;
    opacity: .4;
  }
}

.changeComission--wrapper {
  position: relative;
  overflow-y: auto;
  height: 100%;
}

.interval-afterInputText {
  margin-left: 5px;
}

.interval-comission-col {
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .comission-radio-block {
    p {
      &.header-text {
        font-size: 25px;
      }
    }
  }
}

@media (max-width: 992px) {
  .aap-change-sub-row {
    margin-top: 20px;
  }

  .row-md-mt-20 {
    margin-top: 20px;
  }
  .comission-radio-block p.header-text {
    font-size: 20px;
  }
  .fixedComission-wrapper {
    width: auto;
  }
}

@media (max-width: 576px) {
  .fixedComission--select {
    max-width: 100%;
    margin-left: 0;
  }
  .interval-comission-col {
    margin-top: 0;
  }
  .interval-inputNumber {
    max-width: 100%;
  }
  .interval-afterInputText {
    margin-left: 0;
  }
  .aap-change-radio { 
    .ant-radio-wrapper {
      height: auto;
    }
  }
  .interval-comission {
    margin-top: 0;
  }
}
