.tooltip-details {
  cursor: pointer;
  // background: #ffffff;
  // color: rgb(101,101,101);
}

.ant-tooltip-arrow:before {
  background-color: rgb(0,0,0);
}

.ant-tooltip-inner {
  // color: rgb(101,101,101);
  // background: #ffffff;
  background-color: rgb(0,0,0);
}