.ReportByPaymentTypesGrid-dateRow{
  display: flex;
  margin: -4px -10px;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
}


.ReportByPaymentTypesGrid-dateRowItem{
  display: flex;
  align-items: center;
  padding: 4px 10px;
}

.ReportByPaymentTypesGrid-label{
  width: 60px;
}

.ReportByPaymentTypesGrid-error{
  padding-top: 4px;
  color: red;
}
