.AddServiceTypeByBigTableModal{
  width: 640px !important;

  .GridDropdown-contentWrapper{
    left: 0;
  }
}

.AddServiceTypeByBigTableModal-inputRow{
  .AddServiceByBigTable-label{
    flex: 0 0 110px;
    width: 110px;

    @media (max-width: 800px) {
      flex: 1 1 auto;
      width: auto;
    }
  };
}

.AddServiceTypeByBigTableModal-error{
  color: red;
  font-size: 14px;
}
