.CommissionsTechnicalPartner{
  &-toolbar{
    max-width: 700px;
    padding: 8px;
  }
  &-row{
    display: flex;
    margin-bottom: 8px;
  }
  &-label{
    width: 150px;
  }
  &-btnGroup{
    button{
      margin-right: 5px;
    }
  }
}
