.GeneralServiceProps-fieldRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .GridDropdown-contentWrapper{
    left: 0;
  }
}

.GeneralServiceProps-label {
  flex: 0 0 140px;
  width: 140px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 8px;

  @media (max-width: 800px) {
    flex: 0 0 auto;
    width: auto;
    margin-bottom: 4px;
  }
}

.GeneralServiceProps-input{
  flex: 1 1 auto;
  width: auto;
  max-width: 420px;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 4px;
  }
}

.GeneralServiceProps-serviceTypeAction{
  margin-left: 8px;
  flex: 0 0 auto;
}

.GeneralServiceProps-selectRow{
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  @media (max-width: 800px) {
    flex: 1 1 100%;
    width: 100%;
  }
}

