.EasyPayUsersContainer{
  padding: 16px;

}

.EasyPayUsers-select{
  width: 220px;

  @media (max-width: 860px) {
    width: 240px;
  }
}

.EasyPayUsers-fieldRow{
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.EasyPayUsers-label{
  width: 130px;
}

.EasyPayUsers-shortLabel{
  width: 72px;

  @media (max-width: 860px) {
    width: 130px
  }

}

.EasyPayUsers-toolbar{
  display: flex;
  align-items: center;

  button {
    margin: 0 !important;
  }

  &>div:last-of-type{
    margin-left: 32px;
    margin-right: 40px;
  }

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;

    &>div:last-of-type{
      margin: 8px 0 16px;
    }
  }
}

.EasyPayUsers-input{
  width: 240px;
}
