.PayerPhoneAndCardNumberGrid-toolbar{
  display: flex;
  margin: -4px;
  flex-wrap: wrap;

  &>div{
    padding: 4px;
  }
}

.PayerPhoneAndCardNumberGrid-dialog{
  width: 640px !important;
}

.PayerPhoneAndCardNumberGrid-row{
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.PayerPhoneAndCardNumberGrid-label{
  width: 130px;
  flex: 0 0 130px;

  @media (max-width: 640px) {
    width: auto;
    text-align: left;
    margin-bottom: 6px;
    flex: 1 1 auto;
  }
}

.PayerPhoneAndCardNumberGrid-select{
  width: 486px;
  flex: 0 1 486px;

  @media (max-width: 640px) {
    flex: 1 1 auto;
    width: 100%;
  }
}
