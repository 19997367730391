@import "../../../styles/config";


.cell-progress {
  position: relative;

  .ant-progress-inner {
    background-color: #fff;
    width: 90%;
  }

  div {
    &:first-child {
      display: flex;
      align-items: center;
    }
  }

  .ant-progress-text {
    text-align: right;
    color: #656565;
    margin-left: -4px;
    font-weight: 500;
    font-family: Tahoma, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
    font-feature-settings:"tnum", "tnum";
  }
}

@media (max-width: 992px) {
  .cell-progress {
    .ant-progress-text {
      font-size: 13px;
    }
  }
}