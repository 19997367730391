.ExtendedAddressModal{
  width: 980px !important;

  @media(max-width: 1000px){
    width: 96% !important;
  }
}

.ExtendedAddressForm-label{
  width: 130px;
}

.ExtendedAddressForm-row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -4px;
}

.ExtendedAddressForm-fieldRow{
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  padding: 4px;
  flex-wrap: wrap;
}


.ExtendedAddressForm-input{
  flex: 0 0 300px;
  width: 300px;

  .ant-select{
    width: 100%;
  }

  @media (max-width: 360px) {
    width: 240px;
    flex: 0 0 240px;
  }
}


