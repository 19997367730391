.filter-scroll {
  .k-grid-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //Safari: missunderstanding of flexbox
    height: 100%;
    max-height: 70px;
  }
}

@media (max-width: 992px) {
  .ant-drawer-body div.k-grid-header {
    display: none !important;
  }
}