.ModalServiceAdditionalLimits-row{
  margin-bottom: 16px;
}
.ModalServiceAdditionalLimits-input{
  width: 100%;
}
.ModalServiceAdditionalLimits-globalError{
  color: red;
}

.ModalServiceAdditionalLimits{
  width: 600px !important;

  @media (max-width: 640px) {
    width: 96vw !important;
  }
}

.ModalServiceAdditionalLimits-gridDropdown{

}
