.Users-ownerDropdownRow{
  margin-bottom: 16px;
}

.Users-ownerDropdown{
  margin-right: 16px;
  @media (max-width: 769px) {
    margin-bottom: 10px;
  }
}

.Users-toolbar {
  flex: 1 1 auto;
  @media (max-width: 992px) {
    margin-top: 8px;
  }
}
