.ModalCommissionsTechPartners {
  width: 100% !important;
  max-width: 800px;

  .GridDropdown-contentWrapper{
    left: 0;
  }

  &-col {
    width: 50%;
    @media (max-width: 490px) {
      width: 100%;
    }
  }

  &-row {
    display: flex;
    align-items: center;
    @media (max-width: 490px) {
      flex-wrap: wrap;
    }
  }

  &-label {
    width: 150px;
  }

  &-input {
    width: 650px;
  }

  &-gridDropdown {
    width: 650px;

    &--top {
      .GridDropdown-contentWrapper {
        top: auto;
        bottom: 101%;
        .GridDropdown-content{
          height: 350px;
          @media (max-width: 992px) {
            height: auto;
          }
        }
      }
    }
  }
}

.text-center{
  text-align: center;
  @media (max-width: 490px) {
    text-align: initial;
  }
}
.w-70{
  width: 65%;
  @media (max-width: 490px) {
    width: 100%;
  }
}

.ModalCommissionsTechPartnersDelete-container{
  text-align: center;
}
