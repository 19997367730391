.modal-slider {
  // height: 100%;
  display: flex;
  flex-direction: column;
  // position: relative;
  // padding-top: 20px;
  .k-loading-mask {
    height: 100% !important;
  }
  .ant-modal-body {
    padding: 0;
    height: 100%;
  }
  // .ant-carousel {
  //   height: 400px;
  // }
  .ant-modal-close {
    display: none;
  }
  .ant-carousel .slick-list .slick-slide > div {
    height: 100%;
    position: relative;
    display: flex;
  }
  .slick-next,
  .slick-prev {
    width: 16px;
    height: 28px;
  }
  .slick-next {
    right: -50px;
  }
  .slick-prev {
    left: -50px;
  }

  .slider-wrap {
    position: relative;
    height: 400px;
    // height: 100%;
    // margin-top: 2.5px;

    img {
      margin: auto;
      // height: 100%;
      width: 300px;
      height: 400px;
    }
  }
  .modal-slider--header {
    position: relative;
    height: 40px;
    width: 100%;
    display: flex;
    &.considered {
      background: #1890ff;
    }
    &.notBranded {
      background: #ff7144;
    }
    &.brandedByStandart {
      background: #31ee5a;
    }
    &.underConsideration {
      background: #ffdcdc;
      // background-color: rgba(252,15,192, .6);
    }
    &.branded {
      background: #ffb444;
    }
    padding-left: 6px;
    padding-right: 11px;
    align-items: center;
    .modal-slider--header-left,
    .modal-slider--header-right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .modal-slider--header-left {
      img {
        padding: 5px;
        cursor: pointer;
      }
    }
    .modal-slider--header-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img,
      a {
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
      .thrash-block {
        display: flex;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        span {
          // color: #ffffff;
          margin-left: 10px;
          // font-size: 12px;
        }
        img {
          margin-right: 0;
        }
      }
    }
  }
  .modal-slider-footer {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border-radius: 19px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -70px;
    transition: ease-in-out .3s;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .modal-slider-radio-group {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding-left: 5px;
      padding-right: 5px;
      .ant-radio-wrapper {
        display: flex;
        align-items: center;
        input {
          margin-top: 4px;
        }
        span {
          margin-left: 1px;
          font-size: 12px;
        }
      }
    }

    .switch-block {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 8px;
        color:#101010;
        font-size: 12px;
      }
      .ant-switch {
        min-width: 35px;
        &.branded {
          background-color: #ffffff;
          border: 1px solid #1890FF;
          .ant-switch-loading-icon, &::after {
            background-color: #1890FF;
          }
        }
        &.rejected {
          background-color: #FF7144;
          .ant-switch-loading-icon, &::after {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .modal-slider {
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      left: 25px;
      z-index: 9;
    }
    .modal-slider-footer {
      bottom: -50px;
    }
  }
}
