.gridAdminStockPhotos-toolbar-row {
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  width: 100%;
  h2 {
    margin-bottom: 0;
  }
  .popup-owners-grid-wrapper {
    margin-left: 15px;
    flex: 1;
  }
}
.gridAdminStockPhotos-toolbar-h2 {
  &.owner {
    margin-left: 5px;
  }
}
.gridAdminStockPhotos-toolbar-changeStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 21px;
  min-width: 220px;
  font-size: 14px;
  height: 40px;
  margin-left: 0;
  &:hover , &:active {
    background-color: #1890FF !important;
    color: #FFFFFF !important;
  }
  span.anticon {
    display: flex;
    align-items: center;
    font-size: 8px;
  }
}
.modal-system-encashment--select {
  &.gridAdminStockPhotos-toolbar-photosFilter {
    margin-left: 25px;
    height: 40px;
    font-size: 14px !important;
    min-width: 298px;
    // padding: 12px;
    .ant-select-selection--single {
      height: 40px;
    }
    .ant-select-selection__rendered {
      line-height: 40px;
    }
  }
}

@media(max-width: 576px) {
  .gridAdminStockPhotos-toolbar-row {
    flex-direction: column;
    h2, div, button {
      width: 100%;
    }
    h2 {
      text-align: center;
      margin-top: 10px;
    }
    .popup-owners-grid-wrapper {
      margin-left: 0;
    }
  }
  .modal-system-encashment--select.gridAdminStockPhotos-toolbar-photosFilter {
    margin-left: 0;
    min-width: auto;
  }
}