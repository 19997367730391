.issued-certificates--toolbar.operabilityDay-toolbar {
  input {
    max-width: 200px;
    margin-left: 10px;
  }
  p {
    min-width: 1px;
  }
}

@media(max-width: 576px) {
  .issued-certificates--toolbar.operabilityDay-toolbar {
    margin-top: 10px;
  }
}