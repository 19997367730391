.modal-add-whiteList-row {
  padding-left: 3px;
  padding-right: 3px;
   p {
     margin-bottom: 0;
   }
   .input-warning {
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px;
  } 
}
