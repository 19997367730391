.StepServiceCreation-tab{
  padding: 32px 16px;
}


.StepServiceCreation-serviceIdRow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.StepServiceCreation-serviceIdLabel{
  flex: 0 0 100px;
  width: 100px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 8px;

  @media (max-width: 800px) {
    flex: 0 0 auto;
    width: auto;
    margin-bottom: 4px;
  }
}

.StepServiceCreation-serviceIdInput{
  flex: 1 1 auto;
  width: auto;
  max-width: 420px;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 4px;
  }
}
