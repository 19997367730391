.remote-commands-select {
  width: 100%;
   padding: 5px 12px;
  .ant-select-arrow {
    display: inline-block;
    width: auto;
    .anticon {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 991px) {
  .remote-commands-select {
    .ant-input, .k-input, .ant-select {
      font-size: 14px !important;
    }
  }
}

.ant-select-dropdown-menu{
  max-height: 300px;
}
