.CheckTemplateForm-label{
  flex: 0 0 120px;

  @media (max-width: 640px) {
    flex: 1 1 auto;
    margin-bottom: 8px;
  }
}

.CheckTemplateForm-input{
  flex: 1 1 auto;
}

.CheckTemplateForm-row{
  margin-bottom: 8px;
  display: flex;

  @media (max-width: 640px) {
    flex-direction: column;
  }
}

.CheckTemplateForm{
  padding: 8px;
}

.CheckTemplateForm-areaInput{
  & textarea{
    height: 480px;
  }
}
