
.addRemSets-toolbar-wrapper {
  width: 100%;
  display: flex;
}

.addRemSets-toolbar-subwrapper {
  width: 50%;
  &.right {
    margin-left: 10px;
    .last-btn {
      margin-left: 0;
      margin-top: 9px;
    }
  }
}

.addRemSets--select {
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  .addRemSets-toolbar-wrapper {
    flex-direction: column;
  }
  .addRemSets-toolbar-subwrapper {
    width: 100%;
    &.right {
      margin-top: 10px;
      margin-left: 0;
      margin-bottom: 10px;
      .last-btn {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width:576px) {
  .addRemSets-toolbar-subwrapper {
    button {
      width: 100%;
    }
    &.right {
      .last-btn, .second-btn  {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}