.TerminalDetails {
  padding: 0 16px;
  overflow: auto;
  height: 100%;

  p {
    margin-bottom: 0;
    font-size: 14px;
    &.TerminalDetails-inputLabel {
      box-sizing: border-box;
      padding-right: 4px;
      min-width: 177px;
      text-align: right;
    }
  }

}

.TerminalDetails-row{
  justify-content: space-between;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.TerminalDetails-btnGroup{
  width: 400px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: -2px;

  &>button {
    margin: 2px;
  }

  @media (max-width: 756px) {
    margin-top: 10px;
    justify-content: flex-start;
  }
}

.newTerminal--header-input {
  margin-left: 10px;
}

.TerminalDetails-cardRow {
  flex-wrap: nowrap;
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
  }
}
.TerminalDetails-statusApplication{
  padding: 8px;
}
@media (max-width: 400px) {
  .TerminalDetails {
    .k-datepicker {
      width: 100%;
      margin-left: 0;
    }
  }

  .TerminalDetails-cardRow {
    flex-direction: column;
    p {
      font-size: 14px;
    }
    .ant-select {
      width: 100% !important;
      margin-left: 0;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}

.TerminalDetails-generateIdActionRow{
  margin-top: -6px;
  margin-bottom: 10px;

  button {
    width: 100%;
  }
}

.TerminalDetails-inventoryNumberActionRow{
  margin-top: -6px;
  display: flex;
  justify-content: center;

  button {
    box-sizing: border-box;
    width: 50%;
  }
}

.TerminalDetails-input{
  width: 100% !important;
}

@media(max-width: 992px){
  .TerminalDetails-toggleButton {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 8px;
  }
  .TerminalDetails-statusBtn{
    width: 100%;
  }
}

@media(max-width: 576px){
  .TerminalDetails-cardRow{
    flex-wrap: wrap;

    .TerminalDetails-inputLabel{
      text-align: left !important;
    }
  }

  .TerminalDetails-inventoryNumberActionRow{
   flex-wrap: wrap;

    button {
      width: 100%;

      &:last-child{
        margin-left: 0 !important;
        margin-top: 4px;
      }
    }
  }
}

.MigrateInventoryNumberModal-successfullyAssignedTerminalContent{
  text-align: center;
}

.TerminalDetails-cardAddressRow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.TerminalDetails-inputAddressLabel{
  width: 56px;
  flex: 0 0 56px;
  box-sizing: border-box;
  text-align: right;
  padding-right: 4px;
}

.TerminalDetails-inputAddressAction{
  width: 192px;
  flex: 0 0 192px;
  margin-left: 0;
}

.TerminalDetails-addressInput{
  width: calc(100% - 256px);
  box-sizing: border-box;
  margin-right: 8px;

  @media(max-width: 576px){
    margin-bottom: 4px;
    margin-right: 0;
    width: 100%;
  }
}

.TerminalDetails-addressAdditionalBlock {
  padding: 0 16px 10px;
  flex: 0 0 130px;
}
