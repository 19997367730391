.CustomInput{
  position: relative;
}

.CustomInput--error{
  & input {
    border: 1px solid red;
    outline: none;
  }

  & .ant-input-number:hover {
    border-color: red;
  }

  & .ant-input-number-focused {
    border-color: red;
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
  }
}

.CustomInput-field{
  width: 100% !important;
  box-sizing: border-box !important;
}

.CustomInput-alarmMessage {
  left: 0;
  top: 32px;
  font-size: 12px;
  color: red;
  line-height: 1;
}



.CustomInput-alarmMessage--absolutePositioned{
  position: absolute;
}
