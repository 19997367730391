.terminalsOperability-select-wrapper {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
}
.terminalsOperability-select {
  &.month {
    flex-grow: 1;
    margin-right: 5px;
  }
  &.date-picker {
    flex-grow: 1;
    margin-right: 5px;
  }
  &.operability {
    flex-grow: 1.5;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.terminalsOperability-dropdown-wrapper {
  width: 100%;
  max-width: 90%;
}

@media(max-width: 991px) {
  .terminalsOperability-select-wrapper {
    flex-direction: column;
    align-items: flex-start;
    .terminalsOperability-select {
      width: 100%;
      max-width: 450px;
      &.operability {
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

@media(max-width: 430px) {
  .terminalsOperability-select-wrapper .terminalsOperability-select {
    max-width: 100%;
  }
}