.ServiceSuppliersGrid-toolbar{
  display: flex;
  margin: -4px;
  flex-wrap: wrap;

  &>div{
    padding: 4px;
  }
}

.ServiceSuppliersGrid-inputRow{
  display: flex;
  align-items: center;
  margin-bottom: 22px;


  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ServiceSuppliersGrid-label{
  flex: 0 0 120px;
  box-sizing: border-box;
  text-align: left;

  @media (max-width: 520px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}
