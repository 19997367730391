.createApplication--wrapper {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 20px 10%;
  overflow-y: auto;
  height: 100%;

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
.GridDropdown-content{
  height: 350px;
}
  .popup-owners-grid {
    position: absolute;
    max-width: 700px;
    top: 30px;
    width: 100%;
    height: 300px;
    min-height: 300px;
    max-height: 300px;

    .k-grid {
      height: 300px !important;
      max-height: 300px;
    }

    .outline-subwrapper {
      position: relative;
    }
  }
}

.createApplication--subwrapper {
  background-color: #ececec;
  border: 1px solid #000000;
  padding: 0 20px 50px 20px;
}

.createApplication--btn-save {
  margin-top: 15px;
}

.createApplication--header-row {
  div {
    font-size: 16px;
    font-weight: 700;
  }

  @media (max-width: 576px) {
    div {
      font-size: 14px;
    }
  }
}

.createApplication--row {
  margin-top: 20px;
  flex-wrap: wrap;

  &.space--between {
    justify-content: space-between;
  }

  .ant-input-number {
    width: 100%;
  }
}

.createApplication-elem-label {
  width: 100%;
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
}

.createApplication-radio-group-subrow {
  flex-wrap: nowrap;

  &:last-child {
    margin-left: 10px !important;
  }

  .ant-radio-wrapper {
    display: flex;
    margin-right: 5px;
    .ant-radio {
      margin-top: 1px;
    }
  }
}

.createApplication-radio-group {
  display: flex;
  justify-content: flex-end;
  // width: 100%;
}

.createApplication-radio-group-row {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.createApplication-additionalParams-title {
  margin: 20px 40px 20px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.createApplication--select {
  width: 100%;
}

@media (max-width: 576px) {
  .createApplication--wrapper {
    padding: 10px;

    .popup-owners-grid {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      min-height: 100%;
      max-height: 100%;

      .k-grid {
        height: 100% !important;
        max-height: 100% !important;
      }
    }
  }

  .createApplication--row {
    flex-direction: column;

    .ant-col {
      width: 100%;
    }
  }


  .createApplication-elem-label {
    justify-content: flex-start;
    width: 100%;
  }

  .createApplication-radio-group-subrow:last-child {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
