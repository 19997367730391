.CommissionProfiles-actionsPanel{
  margin-top: 10px;
}

.CommissionProfiles-button{
  margin-right: 4px;

  &:last-child{
    margin-right: 0;
  }
}

.CommissionProfiles-row{
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &:last-child{
    margin-bottom: 0;
  }
}

.CommissionProfiles-label{
  text-align: right;
}

.CommissionProfiles-createProfileForm{
  margin-top: 16px;
}

.CommissionProfiles-select{
  width: 100%;
}

.CommissionProfiles-subRow{
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-child{
    margin-bottom: 0;
  }
}

.CommissionDetails-radioGroup{
  width: 100%;
}

.CommissionProfiles-profileVisibilityLabel{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.CommissionProfiles-infoButtonWrapper{
  font-size: 20px;
  cursor: pointer;
}

.CommissionProfiles-cellButton{
  height: auto;
  padding: 0;
  width: 100%;
  line-height: 1;
}
