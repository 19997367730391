.modal-error {
  .ant-modal-header {
    background-color: #1890ff;
    .ant-modal-title {
      color: #ffffff;
    }
  }
  .ant-modal-close {
    color: #ffffff;
  }
  .ant-result-image {
      margin-bottom: 0;
      // svg {
      //   width: 100%;
      // }
  }
}
