.MFODealersGrid-toolbar {
  display: flex;
  margin: -4px -8px;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    margin: -4px -8px 4px;
  }
}

.MFODealersGrid-toolbarItem {
  padding: 4px 8px;
}

.project-GridDropdown-wrapper-modal .GridDropdown {
  width: 88%;
}

.MFODealersGrid-toolbar.HistoryChanges {
  gap: 20px;
  padding: 10px 20px;
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.MFODealersGrid-historyToolbarItem {
  padding: 0 18px;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.MFODealersGrid-dealerRow {
  gap: 6px;
  margin-bottom: 16px;
}
