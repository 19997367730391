.NonRiskLimitsGrid-actionsPanel{
  margin-top: 10px;
}

.SetLimitsDialog{
  width: 640px !important;

  .ant-modal-content{
    width: 640px;
  }

  @media(max-width: 650px){
    width: 100% !important;

    .ant-modal-content{
      width: 100%;
    }
  }
}

.SetLimitsDialog-InputRow{
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    margin-bottom: 32px;
  }
}

.SetLimitsDialog-actionRow{
  margin-bottom: 32px;

  &:last-child{
    margin-bottom: 0;
  }

  @media (max-width: 576px) {
    margin-bottom: 0;
  }
}


.SetLimitsDialog-timePicker {
  width: 100% !important;
}

.SetLimitsDialog-globalError {
  color: red;
  text-align: center;
}

.NonRiskLimitsGrid-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media(max-width: 420px){
    padding-top: 8px;
  }
}
