.CommissionsServices-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.CommissionsServices-selects{
  .popup-owners-grid-wrapper{
    margin-top: 0;
  }
}

.CommissionsServices-row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.CommissionsServices-label {
  text-align: right;
}

.CommissionsServices-searchAction{
  text-align: right;
  margin-bottom: 24px;
}

.CommissionsServices-modalActions{
  button {
    margin-right: 8px;
  }
}

@media (max-width: 976px) {
  .CommissionsServices-toolbar{
    margin-top: 8px;
  }
}

.CommissionsServices-showResultOfCopyingAction{
  @media (max-width: 652px) {
    margin: 8px 0 0;
  }
}

.CustomSelectedCell{
  padding: 8px 12px;
}

