.cell-stock-status {
    &.mobile {
      width: 100%;
      min-width: 160px;
      .cell-stock-status-span {
        font-size: 12px;
      }
    }
  .cell-stock-status-span {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 5px;
    color: #ffffff;
    position: relative;
    // position: absolute;
    &.notBranded {
      background: #ff7144;
    }
    &.brandedByStandart {
      background: #31ee5a;
    }
    &.underConsideration {
      background: #ffdcdc;
      border: 1px solid #565656;
      color: #565656;
    }
    &.branded {
      background: #ffb444;
    }
    img {
      position: absolute;
      left: 18px;
    }
  }
}

.tooltip-details {
  cursor: pointer;
  // background: #ffffff;
  // color: rgb(101,101,101);
}

.ant-tooltip-arrow:before {
  background-color: rgb(0,0,0);
}

.ant-tooltip-inner {
  // color: rgb(101,101,101);
  // background: #ffffff;
  background-color: rgb(0,0,0);
}
