.EasypayUsersManageGrid-toolbar{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.EasypayUsersManageGrid-fileds{
 display: flex;
  margin: -4px 16px -4px -10px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
}

.EasypayUsersManageGrid-filedRow{
  display: flex;
  align-items: center;
  padding: 4px 10px;

  @media (max-width: 360px) {
   flex-direction: column;
    align-items: flex-start;
  }
}

.EasypayUsersManageGrid-label{
  flex: 0 0 124px;
  width: 124px;

  @media (max-width: 360px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUsersManageGrid-shortLabel{
  flex: 0 0 72px;
  width: 72px;

  @media (max-width: 660px) {
    flex: 0 0 124px;
    width: 124px;
  }

  @media (max-width: 360px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUsersManageGrid-select{
  width: 200px;
  flex: 0 0 200px;

  @media (max-width: 360px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUsersManageGrid-error{
  flex: 0 0 100%;
  width: 100%;
  color: red;
  margin-top: 8px;
}

.EasypayUsersManageGrid-action{
  @media (max-width: 360px) {
    margin-top: 12px;
  }
}
