.CheckTemplateAction-select{
  max-width: 560px;
  margin-bottom: 16px;
}

.CheckTemplate-actions{
  display: flex;
  margin: -8px;
  flex-wrap: wrap;

  &>button{
    margin: 4px 6px;
  }
}
.PreviewTemplateReceipt-container{
  padding: 16px;
}
.Modal-label{
  width: 130px;
}
.align-center{
  align-items: center;
  @media (max-width: 640px) {
    align-items: flex-start;
  }
}
.CheckTemplate-input{
 width: 100%;
}
.position-relative{
  width: 100%;
  position: relative;
}
.err{
  border: 1px solid red;
  &-msg{
    position: absolute;
    top: 100%;
    color: red;
  }
}
.text-center{
  text-align: center;
}
.pt-20{
  padding-top: 20px;
}
