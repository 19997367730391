.DealerCommissionsPaymentTools-toolbar{
  @media (max-width: 992px) {
    margin-top: 8px;
  }
}

.DealerCommissionsPaymentTools-dropdown{
  display: flex;
  align-items: center;

  @media (max-width: 720px) {
    width: 100%;
    box-sizing: border-box;
  }
}

.DealerCommissionsPaymentTools-ownerDropdown{
  width: 600px;
  margin-right: 16px;

  @media (max-width: 720px) {
    width: 100%;
    box-sizing: border-box;
  }
}

.dealerCommissionsPaymentTools-searchButton{
  @media (max-width: 792px) {
    margin-top: 8px;
    margin-left: 0 !important;
  }
}

.DealerCommissionsPaymentTools-row{
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.DealerCommissionsPaymentTools-label{
  width: 64px;
}

.ModalPaymentTool{
  width: 720px !important;
}

.ModalPaymentTool-content{
  margin-top: 16px;

  .GridDropdown-content{
    height: 442px;
  }

  @media (max-width: 720px) {
    .DealerCommissionsPaymentTools-label{
      margin-bottom: 8px;
    }

    .GridDropdown>button{
      margin-left: 0 !important;
    }
  }


}
