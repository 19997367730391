.NavigationMenuElementsGrid-toolbar{
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.NavigationMenuElementsGrid-action{
  flex: 0 0 90px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 640px) {
    margin-top: 16px;
    flex: 0 0 auto;
  }
}


.NavigationMenuElementsGrid-row{
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.NavigationMenuElementsGrid-label{
  flex: 0 0 90px;
  width: 90px;
  text-align: left;

  @media (max-width: 640px) {
   flex: 0 0 auto;
    width: auto;
  }
}

.NavigationMenuElementsGrid-fields{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -4px -8px;
  &>div{
    padding: 4px 8px;
  }

  @media (max-width: 640px) {
    margin: -4px 0;

    &>div{
      padding: 4px 0;
    }
  }
}

.NavigationMenuElementsGrid-selectType{
  width: 220px;

  @media (max-width: 640px) {
    width: 100%;
  }
}


.NavigationMenuElementsGrid-selectLanguage{
  width: 286px;

  @media (max-width: 640px) {
    width: 100%;
  }
}

.NavigationMenuElementsGrid-propValueSelect{
  width: 360px;

  @media (max-width: 640px) {
    width: 100%;
  }
}

.NavigationMenuElementsGrid-modalButton{
  width: 360px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  text-align: left !important;
  margin: 0 !important;

  @media (max-width: 640px) {
    width: 100%;
  }
}

.NavigationMenuElementsGrid-definitedPropRow{
  background-color: lightgreen;
}
