.row-mobile {
  width: 100%;
  padding: 16px 0;
  // border-bottom: 1px solid #000;
  &.row-active {
    background-color: rgba(6, 106, 183, 0.25) !important;
  }

  &.row-bordered {
    border-bottom: 1px solid #000;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    &-disable{
      display: none;
    }
  }

  .left {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    white-space: nowrap;
    min-width: 40%;
  }

  .right {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 992px) {
  // start styles for mobile mode
  .k-grid {
    //   &.mob-desc {
    //     height: 500px;
    // }
    .k-grid-header {
      table {
        thead {
          th {
            & > div {
              & > div {
                width: 30px;
                height: 30px;
                display: flex !important;
                justify-content: center;
                align-items: center;
                bottom: 0;
              }
            }
            .k-icon {
              &.k-i-more-vertical {
                font-size: 16px;
                &:before {
                  content: "\e129";
                }
              }
            }
          }
        }
      }
    }

    &.mobileMode {
      colgroup {
        display: none;
      }
      .k-grid-footer {
        display: none;
      }
      .k-grid-header {
        table {
          display: flex;
          flex-direction: column;
          width: 100% !important;
          thead {
            tr {
              display: none;
              &:last-child {
                display: flex;
                // display: none;
                flex-direction: column;
                // &.active {
                //   display: flex;
                // }
                th {
                  border-left: none;
                  border-right: none;
                  height: 30px;
                  padding: 4px 12px;
                  display: none;
                  &.mobile-visible {
                    display: block;
                  }
                  // height: 35px;
                  &:empty {
                    display: none;
                  }
                }
                // удаление Kendo костыля, который не позволяет скролить ссылки
                .k-link {
                  touch-action: initial !important;
                }
              }
            }
          }
        }
      }
      .row-mobile {
        .item {
          button {
            border: 1px solid rgba(0, 0, 0, 0.08);
            background: #1890ff;
            height: 20px !important;
            color: #fff;
            min-width: 100px;
          }
        }
      }
    }
  }
  .k-loading-mask {
    height: 100vh !important;
    position: fixed !important;
    &.mobileMode--mask {
      height: 100vh;
      overflow: hidden;
    }
  }
}

@media (max-width: 329px) {
  .toolbar-btn-refresh {
    // margin-top: 5px;
    margin-left: 0;
  }
}
