.clientCabinet-actionRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 6px -4px - 4px;

  & > div {
    padding: 4px;
  }
}

.clientCabinet-fieldRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.ChangeClientDataModal-input-col {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.ChangeClientDataModal-input-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.ChangeClientDataModal-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientCabinet-fieldRow-select {
  width: 250px;
}

.OperationHistoryGrid-wrapper {
  display: flex;
  gap: 10px;
}

.transactionTd {
  cursor: pointer;
  color: #1890ff;
  font-weight: 400;
  text-align: center;
  font-size: 14px !important;
  line-height: 14px;
}

.clientCabinet-toolbar {
  display: flex;
  margin-top: 10px;
}

.clientCabinet-toolbar.gap {
  gap: 6px;
}




