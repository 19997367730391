.scrcontrol--wrapper {
  width: 100%;
  max-width: 800px;
  position: relative;
  margin: 0 auto;
  // overflow-y: auto;
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
  .sortable-component-disabled {
    pointer-events: none;
    background: lightgrey;
  }
  .modal-change-profile-row {
    &.for-sub-input {
      margin-left: 0 !important;
      .ant-input {
        width: auto;
        margin-left: 10px;
        min-width: 300px;
      }
    }
  }
  .ant-select-selection--single {
    width: 300px;
  }
  .scrcontrol-select-region {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
