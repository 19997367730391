.ServiceEndpoints-toolbar{
  @media(max-width: 992px){
    margin-top: 8px;
  }

  button {
    margin-left: 0;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  input {
    max-width: 300px;
  }
}

.ServiceEndpoints-serviceInfo{
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ServiceEndpoints-label{
  width: 120px;
  flex: 0 0 120px;
}

.UpdateOperationModal{
  width: 640px !important;
}

.UpdateOperationModal-row{
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UpdateOperationModal-label{
  width: 130px;

  @media (max-width: 640px) {
    width: auto;
    text-align: left;
    margin-bottom: 6px;
  }
}

.UpdateOperationModal-error{
  color: red;
  text-align: center;
}

.UpdatePropsModal--select{
  width: 100%;
}
