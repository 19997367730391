.UpdateAndCreateBrandDialog{
  top: 8px !important;

  .GridDropdown-contentWrapper{
    left: 0;
  }

  .GridDropdown-content{
    width: 496px !important;
  }

  .GridDropdown-title{
    margin-left: 0;
  }
}

.UpdateAndCreateBrandDialog-label {
  margin: 12px 0 8px;
}

.UpdateAndCreateBrandDialog-error {
  color: red;
  margin-top: 4px;
  font-size: 14px;
}

.UpdateAndCreateBrandDialog-textArea {
  height: 400px !important;
  margin-bottom: 16px;
}

.UpdateAndCreateBrandDialog-checkboxRow{
  display: flex;
  align-items: flex-end;

  &>div{
    margin-right: 8px;
  }

  &>label{
    margin-bottom: 8px;
  }
}
