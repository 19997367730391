.addingAddressedPaymentApplications--success {
  p {
    width: 100%;
    margin-bottom: 0;
  }
}

.aap-commands-dropdown {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
}

// @media (max-width: 767px) {
//   .aap-commands-dropdown {
//     margin-top: 15px;
//   }
// }