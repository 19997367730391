.ant-tabs .ant-tabs-top-content div[role="tabpanel"]{
  overflow-y: scroll!important;
}

.AddNewUser-row{
  margin-bottom: 16px;
}
.AddNewUser-card{
  width: 100%;
  margin-bottom: 16px;
}
.AddNewUser-col{
  padding: 16px;
}
.AddNewUser-groupMembershipBtn, .AddNewUser-btnSave{
  display: block;
  margin-left: auto!important;
  margin-bottom: 16px;
}
