.EasypayUserAccountNumberInfoGrid-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
}


.EasypayUserAccountNumberInfoGrid-userInfo{
  display: flex;
  margin: -10px -4px 8px -4px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.EasypayUserAccountNumberInfoGrid-toolbarItem{
  display: flex;
  padding: 4px 10px;
  align-items: center;

  @media (max-width: 340px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.EasypayUserAccountNumberInfoGrid-label{
  flex: 0 0 120px;
  width: 120px;

  @media (max-width: 340px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUserAccountNumberInfoGrid-input{
  flex: 0 0 180px;
  width: 180px;

  @media (max-width: 600px) {
    flex: 0 0 120px;
    width: 120px;
  }

  @media (max-width: 340px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUserAccountNumberInfoGrid-phoneInput{
  flex: 0 0 120px;
  width: 120px;

  @media (max-width: 600px) {
    flex: 0 0 180px;
    width: 180px;
  }

  @media (max-width: 340px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUserAccountNumberInfoGrid-datesInfo{
  display: flex;
  margin: -4px -10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.EasypayUserAccountNumberInfoGrid-datesRow{
  display: flex;
  align-items: center;
  padding: 4px 10px;

  @media (max-width: 340px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.EasypayUserAccountNumberInfoGrid-dateLabel{
  flex: 0 0 60px;
  width: 60px;

  @media (max-width: 600px) {
    flex: 0 0 120px;
    width: 120px;
  }

  @media (max-width: 340px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUserAccountNumberInfoGrid-fieldsContent{
  display: flex;
  flex-direction: column;
}

.EasypayUserAccountNumberInfoGrid-action{
  padding-left: 12px;

  @media (max-width: 720px) {
    padding-left: 0;
    padding-top: 12px;
  }
}

.EasypayUserAccountNumberInfoGrid-error{
  padding-top: 8px;
  flex: 0 0 100%;
  width: 100%;
  color: red;
}
