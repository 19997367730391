.GroupServices{
  &-btnGroup{
    margin-bottom: 16px;
    button{
     margin-right: 8px;
    }
  }
}

.GroupServices-deleteModalContent {
  text-align: center;
}
