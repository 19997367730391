.OTPHistoryToolbar-toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.OTPHistoryToolbar-dateRow {
  display: flex;
  align-items: center;
  padding-right: 24px;

  @media (max-width: 768px) {
    margin-bottom: 8px;
    padding-right: 0;

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.OTPHistoryToolbar-label{
  width: 64px;
}

.OTPHistoryToolbar-datePicker {
  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.OTPHistoryToolbar-error{
  padding-top: 4px;
  color: red;
  flex: 0 0  100%;
}

.OTPHistoryToolbar-ActionRow{
  @media (max-width: 768px) {
    margin-top: 8px;
  }
}
