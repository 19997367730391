.singleMessage-card {
  margin: 20px;
  .newTerminal--card-row {
    p {
      width: 20%;
    }
  }
  .ant-input[disabled] {
    color: #656565;
  }
  .mt-20 {
    margin-top: 20px;
  }
}

.singleMessage--link {
  text-decoration: underline;
  width: 100%;
}

@media (max-width: 767px) {
  .singleMessage-card {
    .newTerminal--card-row {
      p {
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .singleMessage-card {
    margin: 5px;
    .newTerminal--card-row {
      width: 100%;
      .ant-card-type-inner .ant-card-body {
        padding: 12px;
      }
      textarea {
        margin-top: 10px;
      }
    }
  }
}

