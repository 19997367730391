.RecalculationTransactions{
  &-toolbar{
    margin-bottom: 16px;
  }
  &-content{
    padding: 8px;
  }
  &-col{
    display: flex;
    flex-direction: column;
    &--datepicker{
      width: 390px;
    }
  }
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &--nowrapp{
      flex-wrap: nowrap;
    }
    @media (max-width: 763px) {
      flex-wrap: wrap;
    }
  }
  &-label{
    width: 120px;
    padding: 0 8px 0 0;
  }
  &-input{
    width: 100%;
  }
  &-card{
    width: 800px;
    //margin-bottom: 16px;
    @media (max-width: 772px) {
      width: 100%;
    }
    &-title{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
  &-dropDown{
    @media (max-width: 645px) {
      width: 100%;
    }
      .GridDropdown-title{
        width: 680px;
        margin-left: 0;
        @media (max-width: 645px) {
          width: 100%;
        }
      }
    }
  &-datepicker{
    position: relative;
    .k-widget.k-datepicker{
      width: 270px!important;
    }
  }
  &-error{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
    color: red;
  }
  &-btnGroup{
    padding: 8px;
    margin-bottom: 16px;
    button:not(:last-of-type){
      margin-right: 8px;
    }
  }
  &-globalError{
    color: red;
    margin-bottom: 16px;
  }
  &-dealerCommissions-title{
    font-size: 16px;
    font-weight: bold;
    padding: 8px;
  }
}
.RecalculationTransactionsCustom{
  &-label{
    min-width: 170px;
    padding: 0 8px 0 0;
  }
  &-input{
    width: 100%;
  }
}
.SupplierCommissionsForm-card{
  margin-bottom: 16px;
  width: 350px;
  @media  (max-width: 772px) {
    width: 100%;
  }
  .RecalculationTransactionsCustom-label{
    min-width: 125px;
  }
  .RecalculationTransactions-row{
    margin-bottom: 24px;
  }
}
.SupplierCommissionsForm-card-title{
  font-weight: bold;
  margin-bottom: 16px;
}
.justify-between{
  justify-content: space-between;
}
.flex-wrap{
  @media (max-width: 780px) {
    flex-wrap: wrap;
  }
}
.SupplierCommissions-form{
  position: relative;
  padding: 16px 5px 0 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;

  .RecalculationTransactions-globalError{
    position: absolute;
    bottom: 0;
    left: 5px;
  }
}
