.modal-change-profile-row {
  .disableChangeProfile-radio-group {
    width: 100%;
    label.ant-radio-wrapper {
      display: flex;
      justify-content: center;
      .ant-radio {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        span {
          margin-left: 5px;
        }
      }
    }
  }
}