.CardsAvailability-toolbar{
  width: 100%;
}

@media (max-width: 992px) {
  .CardsAvailability-toolbar{
    margin-top: 8px;
  }
}

.CardsAvailability-row{
  display: flex;
  align-items: center;
}
