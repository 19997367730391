

.GridDropdown--active{
  position: relative;
}

@media(max-width: 992px){
  .GridDropdown{
    z-index: 2;
  }

  .GridDropdown--active {
    position: fixed;
  }
}

.GridDropdown-outsideArea {
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none !important;
  z-index: 21;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;

  &.open {
    display: block;
  }
}

.GridDropdown-title{
  width: 100%;
  justify-content: flex-start !important;
  padding: 6px 10px !important;
  margin: 0;

  .GridDropdown-titleTextWrapper{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.GridDropdown-contentWrapper{
  position: absolute;
  left: 5px;
  top: 30px;
  z-index: 22;
}

.GridDropdown-content{
  box-sizing: border-box;
  height: 480px;
  width: 420px;
  background-color: #f6f6f6;
  border: 1px solid #ebebeb;
}

.GridDropdown-grid {
  height: calc(100% - 40px) !important;

  .k-grid-header-wrap {
    thead {
      tr {
        &.k-filter-row {
          display: none;
        }
      }
    }
  }
}

.GridDropdown-disableHeaderCheckbox {
  .k-grid-header-wrap {
    thead {
      .k-checkbox {
       display: none;
      }
    }
  }
}

.GridDropdown-gridSearchPanel{
  display: flex;
  align-items: center;
}

.GridDropdown-gridSearchActions{
  flex: 0 0 auto;
}

.GridDropdown-button{
  margin-right: 4px;
  background-color: #f6f6f6;

  &:last-child {
    margin-right: 0;
  }
}

.GridDropdown-gridSearchInput{
  flex: 1 1 auto;
  margin-right: 8px;
  box-sizing: border-box;
}

.GridDropdown-footer{
  padding: 4px;
  display: flex;
  justify-content: flex-end;
  &-between{
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .GridDropdown-contentWrapper{
    position: relative;
  }

  .GridDropdown-content{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  .GridDropdown-grid{
    height: 100% !important;
    overflow-x: hidden;

    .k-grid-header {
      display: none;
    }
  }

  .GridDropdown-gridToolbarActions{
    display: flex;

    button {
      flex: 1 1 auto;
    }
  }

  .GridDropdown-gridSearchInput{
    margin-right: 0;
    width: 100% !important;
  }

  .GridDropdown-searchField{
    margin-bottom: 8px;
    position: relative;
  }

  .GridDropdown-searchCloseButton{
    position: absolute;
    top: -1px;
    right: 0;
    border: none;
    background-color: transparent;

    &:focus{
      background-color: transparent;
    }
  }
}

.GridDropdown-checkBoxActionRow{
  margin-top: 4px;
  display: flex;
  align-items: center;
}
