.issued-certificates--toolbar {
  display: flex;
  align-items: center;
  &.aligned {
    margin-top: 10px;
    margin-left: 5px;
  }
  p {
    min-width: 100px;
  }
  input {
    max-width: 100px;
  }
}
