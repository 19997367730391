// Hover overriding!

.ant-checkbox-wrapper {
  vertical-align: middle;
}
.ant-popover-placement-topLeft {
  z-index: 9999;
}
.ant-dropdown-open {
  .anticon,
  i {
    color: $light_blue;
  }
  .anticon + span {
    color: $light_blue;
    width: 100%;
    a {
      width: 100%;
    }
  }
  sup {
    background-color: $light_blue;
    i {
      color: $white;
    }
  }
}


i {
  &.ant-dropdown-open {
    color: $light_blue;
  }
}

.ant-dropdown-menu {
  &.user-dropdown {
    .ant-spin-container {
      .ant-list-item {
        span {
          margin-left: 5px;
        }
      }
    }
  }
}

.ant-menu-item {
  display: flex;
  align-items: center;

  span {
    width: 100%;
    display: flex;
    a {
      // color: $white;
      color: rgba(255, 255, 255, 0.65);
      font-size: 15px;
      width: 100%;
    }
  }
  .anticon {
    font-size: 21px;
    margin-right: 20px;
  }

  &.ant-menu-item-selected {
    span {
      a {
        color: $white;
        font-size: 15px;
      }
    }
  }

  .anticon + span {
    font-size: 15px;
    color: $white;
  }
  &:hover {
    span  {
      a {
        color: $white;
      }
    }
  }
}

.ant-menu-submenu-title {
  .anticon + span {
    // color: $white;
    font-size: 15px;
  }
  .anticon {
    font-size: 21px;
    margin-right: 20px;
  }
}

.ant-modal-wrap {
  z-index: 100;
}
.ant-modal-mask {
  z-index: 99;
}

.ant-modal {
  z-index: 101;
}

.ant-drawer-body {
  padding: 0;
  width: 100%;
  height: 100%;

  .ant-layout-sider {
    height: 100%;
  }
}
@media (max-width: $resp_lg) {
  .ant-select-selection--single {
    height: 38px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
  }
  .ant-drawer {
    z-index: 2;
  }
}
