.GridTerminalsConfig{
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.GridTerminalsConfig-select{
  width: 560px;
  margin-right: 16px;

  @media (max-width: 720px) {
    margin-right: 0;
    margin-bottom: 8px;
    width: calc(100vw - 36px);
  }
}
