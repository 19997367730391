.CommissionServiceDetails{
  background: #f0f2f5;
  padding: 30px;
}

.CommissionDetails-commissionNameRow{
  margin-bottom: 64px;
}

.CommissionDetails-commissionNameField{
  display: flex;
  align-items: center;
}

.CommissionDetails-commissionNameLabel{
  margin-right: 16px;
}

@media (max-width: 1200px) {
  .CommissionDetails-commissionNameLabel{
    margin-right: 0;
  }
}
