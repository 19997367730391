.addSets-modal-cellVal {
  .ant-modal-body {
    padding: 10px 16px;
  }
  .ant-modal-header {
    padding: 16px;
  }
  .ant-modal-close {
    margin-right: -5px;
  }
}