.MonitorActivity-toolbar{
  padding-bottom: 20px;
}

.gridTerminalTransactions-search{
  display: flex;
  margin: 0 -24px -8px;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  &>div{
    padding: 8px 24px;
  }
}

.gridTerminalTransactions-action{
  flex: 0 0  auto;
}

.gridTerminalTransactions-fields{
  display: flex;
  flex-wrap: wrap;
  margin: -4px -8px;

  &>div{
    padding: 4px 8px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }

  .date-range-wrapper{
    margin-top: 0;
  }
}
