.UnsuccessfulPaymentsAnalysisGrid-toolbarContent{
  display: flex;
  flex: 0 1 auto;
  max-width: 980px;
  align-items: center;
  flex-wrap: wrap;
  margin: -8px -12px;

  @media (max-width: 1160px) {
    max-width: 560px;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-toolbarContentItem{
  padding: 8px 12px;

  @media (max-width: 992px) {
    width: 100%;
    box-sizing: border-box;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-selectRow{
  display: flex;
  align-items: center;

  .GridDropdown{
    width: 420px;

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .GridDropdown-contentWrapper{
    left: 0;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-dateRow{
  display: flex;
  align-items: center;
  margin: 0 -8px;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    margin: -8px 0;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-fieldRow{
  display: flex;
  align-items: center;
  padding: 0 8px;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-dateLabel{
  flex: 0 0 64px;
  width: 64px;

  @media (max-width: 640px) {
    flex: 1 1 auto;
    width: auto;
  }
}

.UnsuccessfulPaymentsAnalysisGrid--increasedDataLabelWith{
  @media (max-width: 1160px) {
    flex: 0 0 104px;
    width: 104px;
  }

  @media (max-width: 640px) {
    flex: 1 1 auto;
    width: auto;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-selectLabel{
  flex: 0 0 90px;
  width: 90px;

  @media (max-width: 1160px) {
    flex: 0 0 104px;
    width: 104px;
  }

  @media (max-width: 640px) {
    flex: 1 1 auto;
    width: auto;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-longSelectLabel{
  flex: 0 0 104px;
  width: 104px;

  @media (max-width: 640px) {
    flex: 1 1 auto;
    width: auto;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-toolbarAction{
  flex: 0 1 auto;
  padding-left: 16px;

  @media (max-width: 992px) {
    padding-left: 0;
    padding-top: 16px;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-select{
  width: 300px;

  @media (max-width: 1160px) {
    width: 420px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
}

.UnsuccessfulPaymentsAnalysisGrid-error{
  color: red;
  width: 100%;
  flex: 0 0 100%;
  padding-top: 8px;
}
