.SubBrandsTerminalsGrid-dropdownWrapper{
  max-width: 620px;
  margin: 8px 0;
}

.ChangeBrandModal{
  top: 8px !important;
}

.ChangeBrandModal-content{
  .ChangeBrandModal-actionWrapper{
    margin-bottom: 12px;

    @media (min-width: 992px) {
      .GridDropdown-content{
        width: 496px !important;
      }
    }
  }
}

.ChangeBrandModal-label{
  margin-bottom: 8px;
}

