.CashCollectors-dealerRow{
  margin-bottom: 16px;
}

@media (max-width: 992px) {
  .CashCollectors-dateRow{
    margin-bottom: 8px;
  }
}

.GridDropdown{
  width: 100%;
}
