.AddSupplierModal-inputRow{
  display: flex;
  align-items: center;
  margin-bottom: 22px;


  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.AddSupplierModal-label{
  flex: 0 0 120px;
  box-sizing: border-box;
  text-align: left;

  @media (max-width: 520px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}
