@import "../styles/config.scss";

.footer-links {
  position: absolute;
  bottom: 8px;
  width: 100%;
  height: 32px;
  overflow: hidden;
  text-align: center;

  a {
    margin-right: 10px;
  }
}

@media (max-width: $resp_lg) {
  footer {
    &.ant-layout-footer {
      .ant-row-flex {
        flex-direction: column;

        .ant-col {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
