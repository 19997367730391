.SuppliersBalanceGrid-dateFields {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.SuppliersBalanceGrid-label{
  width: 96px;
}

.SuppliersBalanceGrid-fields{
  &>span:first-of-type{
    margin-right: 8px;

    @media (max-width: 470px) {
      margin-bottom: 8px;
    }
  }
}

.ChangeLimitModal-row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.SuppliersBalanceGrid-input{
  flex: 1 1 auto;

  &>div{
    width: 100%;
  }
}

.SuppliersBalanceGrid-error{
  color: red;
  flex: 0 0 100%;
}

.UpdateBalanceModal-input{
  flex: 1 1 auto;

  @media (max-width: 520px) {
    width: 100%;
  }
}

.UpdateBalanceModal-inputRow{
  display: flex;
  align-items: center;
  margin-bottom: 22px;


  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UpdateBalanceModal-label{
  flex: 0 0 120px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;

  @media (max-width: 520px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}
