.EasypayUserAccountNumbersGrid-toolbar{
  display: flex;
  margin: -4px -10px;
  flex-wrap: wrap;
}

.EasypayUserAccountNumbersGrid-toolbarItem{
  display: flex;
  padding: 4px 10px;
  align-items: center;

  @media (max-width: 360px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.EasypayUserAccountNumbersGrid-label{
  flex: 0 0 120px;
  width: 120px;

  @media (max-width: 360px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUserAccountNumbersGrid-input{
  flex: 0 0 180px;
  width: 180px;

  @media (max-width: 360px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.EasypayUserAccountNumbersGrid-phoneInput{
  flex: 0 0 120px;
  width: 120px;

  @media (max-width: 360px) {
    flex: 0 0 auto;
    width: auto;
  }
}
