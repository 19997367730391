.CheckCardRouting-status{
   display: flex;
   flex-wrap: wrap;
   margin: -4px 0;
 }

.CheckCardRouting-statusItem{
  display: flex;
  align-items: center;
  margin-right: 24px;
  padding: 4px 0;

  &:last-of-type{
    margin-right: 0;
  }
}

.CheckCardRouting-statusColorBlock{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 4px;
}

.CheckCardRouting-statusTitle{
  line-height: 1;
}

.CheckCardRouting-action{
  margin: 24px -4px -4px;
  display: flex;
  flex-wrap: wrap;

  &>div{
    margin: 0;

    padding: 4px;
  }
}

.CheckCardRoutingProcessDialog-content{
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
}

.CheckCardRoutingProcessDialog-statusCell{
  border-left: 1px solid gray !important;
  border-right: 1px solid gray !important;
  border-bottom: 1px solid gray !important;
}
