.UserProperties-form{
  height: 100%;
  width: 100%;
  padding: 25px;
  overflow: auto;
}
.UserProperties-row, .CoderCollector-input{
  margin: 0 0 16px 0;
}
.UserProperties-header{
  flex: 1;
}
.UserProperties-headerInput{
  width: auto;
  margin-left: 10px!important;
}
.UserProperties-cardMainProperties{
  margin-bottom: 16px;
}
.UserProperties-headerBtnSave{
  display: flex;
  align-self: end;
  margin-left: auto;
  @media (max-width: 488px) {
    margin-left: 0;
    margin-top: 16px;

  }
}
.CoderCollector-error{
  color: red;
  text-align: center;
}

.UserProperties-btnShowAdditionalInfo{
  @media (max-width: 390px){
    margin-bottom: 10px!important;
  }
}
.UserProperties-btnChange{
  margin-left: 16px!important;
}
.UserProperties-btnSave{
  display: block!important;
  margin-left: auto!important;
}


