.CashDebtByCollectorsGrid-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .k-button{
    margin: 0;
  }

  @media(max-width:992px){
    margin-top: 8px;
  }
}

.CashDebtByCollectorsGrid-selectRow {
  width: 630px;
  margin-bottom: 8px;

  .GridDropdown-contentWrapper{
    left: 0
  }

  @media (max-width: 664px) {
    width: 100%;

    .GridDropdown-content{
      width: auto;
    }
  }
}

.CashDebtByCollectorsGrid-toolbarRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.CashDebtByCollectorsGrid-datePicker {
  width: 160px !important;

  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.CashDebtByCollectorsGrid-datePickersRow{
  display: flex;
  flex-wrap: wrap;
}


.CashDebtByCollectorsGrid-error{
  color: red;
  margin-top: 4px;
  flex: 0 0 100%;
}

.CashDebtByCollectorsGrid-label{
  width: 80px
}

.CashDebtByCollectorsGrid-datePickerRow{
  display: flex;
  align-items: center;
  margin-right: 48px;

  @media (max-width: 564px) {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.CashDebtByCollectorsGrid-button{
  margin-left: 32px !important;

  @media (max-width: 770px) {
    margin-top: 8px;
    margin-left: 0 !important;
  }
}
