.MigrateInventoryNumberModal{
  width: 90vw !important;
  height: 86vh !important;
  .ant-modal-content {
    height: 100%;
  }

  .ant-modal-close{
    z-index: 2 !important;
  }

  .MigrateInventoryNumberModal-grid{
    height: calc(83vh - 177px) !important;
  }
}

.MigrateInventoryNumberModal-headerTitle{
  text-align: center;
  margin-bottom: 16px;
}

.MigrateInventoryNumberModal-headerAction{
  display: flex;

  button{
    width: 100%;
  }
}

.MigrateInventoryNumberModal-actionHeaderCol{
  padding: 0 8px;
}

.MigrateInventoryNumberModal-ownerDropdown{
  margin-bottom: 16px;
  margin-left: -4px;
}

@media (max-width: 992px) {
  .MigrateInventoryNumberModal {
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;

    .MigrateInventoryNumberModal-grid{
      height: calc(100vh - 177px) !important;
    }
  }

  .ant-modal-wrap{
    z-index: 2 !important;
  }

  .ant-modal-mask{
    z-index: 1 !important;
  }
}

@media (max-width: 576px) {
  .MigrateInventoryNumberModal {
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;

    .MigrateInventoryNumberModal-grid{
      height: calc(100vh - 220px) !important;
    }
  }
}
