.ModalBeneficiaries{
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  &-label{
    width: 150px;
  }
  &-input{
    position: relative;
    width: 100%;
  }
  .err-msg{
    position: absolute;
    top: 100%;
    left: 0;
    color: red;
  }
}
.position-relative{
  position: relative;
}
