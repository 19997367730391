.FiscalizedTransactionsOwnersDaysReportGrid-toolbar{
  display: flex;
  align-items: center;

  .GridDropdown-contentWrapper{
    left: 0;
  }

  @media (max-width: 760px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.FiscalizedTransactionsOwnersDaysReportGrid-dataFields{
  display: flex;
  margin: 8px -10px -4px;
}

.FiscalizedTransactionsOwnersDaysReportGrid-fieldRow{
  display: flex;
  align-items: center;
  padding: 4px 10px;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.FiscalizedTransactionsOwnersDaysReportGrid-label{
  width: 60px;

  @media (max-width: 1480px) {
    width: 120px;
  }

  @media (max-width: 760px) {
    margin-bottom: 4px;
  }
}

.FiscalizedTransactionsOwnersDaysReportGrid-error{
  color: red;
}

.FiscalizedTransactionsOwnersDaysReportGrid-searchAction{
  flex: 0 0 auto;
  padding-left: 24px;

  @media (max-width: 760px) {
    padding-left: 0;
    padding-top: 12px;
  }
}

.FiscalizedTransactionsOwnersDaysReportGrid-select{
  width: 480px;

  @media (max-width: 520px) {
    width: 100%;
  }
}
