.RecalculationModal-content{
  padding: 40px 8px 0;
  text-align: center;
  margin-top: -24px;
}

.GridEncashmentTerminals-dropdownWrapper{
  max-width: 640px;
}

.GridEncashmentTerminals-toolbar{

  .GridDropdown-content{
    width: 640px;
  }
}
