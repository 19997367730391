.CustomRadioButtonGroupContainer {
  display: flex;

  & > button{
    margin-left: 0 !important;

    &:first-of-type{
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type{
      border-radius: 0 8px 8px 0;
    }
  }
}
