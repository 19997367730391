.cell-dropdown-btn {
  width: 100%;
  margin: 3px;
  height: auto;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .cell-dropdown-btn {
    justify-content: center;
  }
}

.BaseGridCellDropdown{
  .ant-divider{
    margin: 8px 0;
  }
}
