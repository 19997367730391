@import "../styles/config.scss";

.ant-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-tabs-top-content {
    height: calc(100% - 40px);
    div {
      &[role="tabpanel"] {
        height: 100%;
        overflow: hidden;
        &[aria-hidden="true"] {
          height: 0;
        }
        .k-grid {
          height: 100%;
          position: relative;
        }
      }
    }
  }
  .ant-tabs-nav {
    .ant-tabs-tab-active {
      font-weight: 400;
    }
  }

  .ant-tabs-nav-wrap {
    background: #f6f6f6;
    border-top: 1px solid rgb(228, 228, 228);
    border-right: 1px solid rgb(228, 228, 228);
  }

  &.ant-tabs-card {
    .ant-tabs-card-bar {
      .ant-tabs-tab {
        border-top: none;
        // .ant-tabs-close-x {
        //   border: 1px solid #d9d9d9;
        //   margin-left: 15px;
        //   width: 19px;
        //   height: 17px;
        //   font-size: 15px;
        // }
      }
      .ant-tabs-tab > div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.ant-tabs-bar {
  margin: 0;
  border-bottom: none;
}

@media (max-width: $resp_lg) {
  .ant-tabs {
    .ant-tabs-top-content {
      height: 100%;
    }
  }
}

@media (max-width: $resp_sm) {
  .ant-tabs-bar {
    overflow-x: auto;
    width: 100%;
  }
  .ant-tabs-nav-scroll {
    overflow-x: auto;
  }
  .ant-tabs-nav-container {
    overflow-x: auto;
  }
  .ant-tabs-tab-next,
  .ant-tabs-tab-prev {
    display: none;
  }
  .ant-tabs-nav {
    overflow-x: auto;
    transform: none !important;
  }
  .ant-tabs-nav-container-scrolling {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-tabs-extra-content {
    display: none;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 400;
  }
  // .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  //   margin-left: 15px;
  //   margin-top: -22px;
  //   margin-right: -16px;
  //   font-size: 14px;
  // }
}

// .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
//   z-index: 99999;
// }
