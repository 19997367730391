.MenuProfilesGrid{
  min-height: 100px;

&-contextMenu{
  position: absolute;
  top: 35px;
  left: 0;
  width: 300px;
  z-index: 999;
}
  &-customCell{
   position: relative;
    overflow: visible!important;
  }
  &-btnMenu{
    height: 25px!important;
    margin-bottom: 4px;
  }

  .k-detail-cell{
    padding: 0 !important;
  }

  .MenuProfilesGrid-rowWithOutChild{
    .k-hierarchy-cell{
      opacity: 0;
    }
  }

  .k-grid-content{
    overflow: auto;
  }
}

.MenuProfilesGrid--withHidedCellHeaders{
  .k-header{
    display: none !important;
  }


}

.MenuProfilesGrid-cellDropdown{
  & button {
    background-color: lightgray;
    color: black;
    border: none;

    &:focus{
      background-color: lightgray;
      color: black;
    }

    &:hover{
      background-color: lightgray;
      color: black;
    }
  }
}
