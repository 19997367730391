.UpdateMenuElementModal-inputLabel{
  margin-bottom: 4px;
}

.UpdateMenuElementModal-input{
  margin-bottom: 8px;
}

.UpdateMenuElementModal-error{
  text-align: center;
  color: red;
  font-size: 14px;
}
