.DealerCommissionsServices-toolbarWrapp{
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .GridDropdown-content{
    height: 420px;
  }

  @media (max-width: 992px) {
    padding-top: 8px;
  }
}

.DealerCommissionsServices-selects {
  @media (max-width: 662px) {
    width: 100%;
  }
}

.DealerCommissionsServices-dropdown{
  width: 611px;

  @media (max-width: 662px) {
    width: 100%;
  }
}

.DealerCommissionsServices-row{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.DealerCommissionsServices-searchBtn{
  display: block;
  margin-left: 24px !important;
  margin-bottom: 16px;

  @media (max-width: 830px) {
    margin-left: 0 !important;
    margin-bottom: 24px;
  }
}

.DealerCommissionsServices-controlBtn{
  margin-bottom: 8px;
  button{
    margin:0 5px 8px 0;
  }
}

.DealerCommissionsServices-label{
  width: 86px;
}

.DealerCommissionsServices-centredCell{
  text-align: center !important;
}
.DealerCommissionsServices-statusCell{
  text-align: center !important;
  border-bottom: 1px solid gray !important;
  color: blue;
  cursor: pointer;
}
