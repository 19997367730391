// from pages.scss
.k-grid-virtual .k-grid-content .k-grid-table {
  tbody {
    width: 100%;
    tr {
      &.k-grid-norecords {
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
}

// .k-grid-content.k-virtual-content  {
//   height: 100%;
// }
// .k-grid-content.k-virtual-content > div:first-child {
//   height: 100%;
// }

.k-dialog-wrapper {
  // top: 5px !important;
  // bottom: 20px !important;
  .k-dialog {
    overflow: auto;
    margin: 15px;
  }
}
.k-master-row .k-grid-content-sticky {
  border-bottom-width: 1px;
  border-bottom-color: rgba(40,40,40, .2) !important;
}
.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {
  background-color: #ededed !important;
}

.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td {
  background-color: #bad3e6 !important;
}

// FIX HIERARCHY CELL BUG
.k-grid .k-hierarchy-cell > .k-icon {
  padding: 0 !important;
}

// START IMPORTANT RULE FOR FIXING VIRTUAL SCROLL BUG

.k-grid-virtual .k-grid-content > .k-height-container {
  float: none !important;
}

// END IMPORTANT RULE FOR FIXING VIRTUAL SCROLL BUG
.base-grid {
  // new rule for pages without tabs
  height: 100%;
  .lg-p-0 {
    padding: 0 !important;
  }
  .lg-w-100 {
    width: 100%;
  }
  // new rule for column menu
  th.k-header > div > div {
    &:active,
    &:hover,
    &:focus {
      color: #fff !important;
      background-color: orange !important;
    }
    span {
      font-size: 20px;
    }
  }

  table td span {
    // display: none;
    &.value,
    &.bar {
      display: block;
    }

    &.ant-checkbox {
      display: block;
      span {
        display: block;
      }
    }
  }
  // from pages.scss

  &.k-grid {
    .k-grid-table {
      height: 100%;
    }
    //START EXTRA STYLES FOR CHANGING HEIGHT OF TD!
    td {
      padding: 0 8px;
      white-space: nowrap;
      //  height: 25px !important;
      p {
        font-size: 12px;
        font-family: Tahoma;
      }
      &.wrap {
        white-space: wrap;
      }
    }
    //END EXTRA STYLES FOR CHANGING HEIGHT OF TD!
  }
}
.k-grid th,
.k-grid td {
  outline: none !important;
}

button,
select {
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .k-grid {
    .k-grid-content > div {
      tr {
        &.k-grid-norecords {
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
    .lg-p-0 {
      padding: 0 15px !important;
    }
    .lg-w-100 {
      width: auto;
    }
  }

  .ant-empty-image {
    svg {
      max-width: 90px;
    }
  }
  // from pages.scss
  .base-grid {
    .k-pager-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
      .k-pager-info {
        justify-content: center;
      }
      .k-pager-sizes {
        &.k-label {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .base-grid.k-grid .k-grid-table {
    height: auto;
  }
  .k-treeview .k-in {
    font-size: 10px;
  }
  .k-dialog-wrapper {
    top: 50px;
    .k-window-title {
      font-size: 14px;
    }
    .k-dialog-buttongroup {
      button {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 480px) {
  .k-dialog-wrapper .k-dialog {
    width: 100%;
  }
}

div[data-sortable-component]{
  touch-action: auto!important;
}

.baseMobileGrid{
  height: auto !important;
}

@import "./toolbar/toolbar.scss";
