.ElementsMatrixGrid-container{
  position: relative;
  height: 88%;
}

.ElementsMatrixGrid-disabledWrapper{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.1);
  z-index: 1;
}

.ElementsMatrixGrid{

  .k-grid-container{
    width: 100%;
  }

  .k-grid-header{
    width: 100%;
  }

  tr{
    height: 20px;
  }
}

.ElementsMatrixGrid-header{
  padding: 4px 0;
  display: flex;
  flex-wrap: wrap;

  &>div{
    padding: 4px 4px;
  }
}

.ElementsMatrixGrid{
  th.k-header > div > div {
    &:active,
    &:hover,
    &:focus {
      color: #fff !important;
      background-color: orange !important;
    }
    span {
      font-size: 20px;
    }
  }

  .k-detail-row{
    background-color: rgba(0, 0, 0, 0.6) !important;

    &:hover{
      background-color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}

.ElementsMatrixGrid-selectedBlock{
  width: 16px;
  height: 16px;
  background-color: #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ElementsMatrixGrid-selectedBlockItem{
  width: 10px;
  height: 2px;
  background-color: white;
}

.ElementsSubGrid{
  thead {
    tr:hover{
      background-color: transparent !important;
    }

      th{
        padding: 0 12px !important;
        color: white;
      }
  }

  .k-grid-header-wrap{
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .k-grid-header{
    padding: 0 !important;
  }

  .k-grid-container{
    overflow: hidden;

    .k-grid-content{
      background-color: rgba(0, 0, 0, 0.6) !important;
      overflow: hidden;
    }

    tr{
      color: white;

      &:hover{
        color: white;
      }

      &:nth-of-type(2n){
        background-color: rgba(0, 0, 0, 0.6) !important;
      }

      &:nth-of-type(2n+1){
        background-color: rgba(0, 0, 0, 0.4) !important;
      }
    }
  }
}
