@media (max-width: 1200px) {
    .lg-mt-10 {
      margin-top: 10px;
    }
  }

  @media (max-width: 991px) {
    .md-mt-10 {
      margin-top: 10px;
    }
    .transaction-input {
      height: 38px;
      font-size: 16px;
    }
  }

.GridTransactionsDetail-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px;

  &>div{
    padding: 4px;
  }
}

.PaymentInstructionModal-error{
  color: red;
  font-size: 14px;
}

.GridTransactions-checkboxActionsRow{
  display: flex;
  flex-wrap: wrap;
  margin: 4px -12px -4px;

  &>div{
    padding: 4px 12px;
  }
}

.GridTransactions-checkboxRow{
  display: flex;
  align-items: center;

  &>div{
    margin-left: 8px;
    padding-bottom: 1px;
  }
}


.GridTransactions-fieldsCol{
  padding: 0 !important;

  .ant-select{
    z-index: 1;
  }
}


.GridTransactions-searchActionWrapper {
  display: flex;
  align-items: center;

  &>button {
    padding: 16px;

    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

.GridTransactionsExpandSearchOptionModal{
  width: 600px !important;

  .GridDropdown-contentWrapper{
    left: 0;
  }
}

.GridTransactionsExpandSearchOptionModal-prop{
  display: flex;
  margin: -4px -8px 8px;

  &>div{
    flex: 1 1 50%;
    padding: 4px 8px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;


    &>div{
      flex: 1 1 100%;
      width: 100%;
    }
  }

}

.GridTransactionsExpandSearchOptionModal-checkboxRow {
  flex-direction: row !important;
}

.GridTransactionsExpandSearchOptionModal-row {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.GridTransactionsExpandSearchOptionModal-label {
  flex: 0 0 104px;
  width: 104px;
  text-align: left;

  @media (max-width: 600px) {
    width: auto;
    flex: 1 1 auto;
  }
}

.GridTransactionsExpandSearchOptionModal-checkboxLabel {
  flex: 0 0 136px;
  width: 136px;
}

.GridTransactionsExpandSearchOptionModal-error{
  color: red;
  font-size: 14px;
  text-align: center;
}

.GridTransactionsSplittingTransactionModal-label {
  flex: 0 0 120px;
  width: 120px;
  text-align: left;

  @media (max-width: 600px) {
    width: auto;
    flex: 1 1 auto;
  }
}


.GridTransactionsSplittingTransactionModal-transactionRow{
  margin-bottom: 18px;
}

.GridTransactionsSplittingTransactionModal-input{
  width: 100%;
}

.GridTransactions-errorMessage{
  color: red;
}

.GridTransactions-warningMessage{
  color: orange;
}

.GridTransactions-messages{
  margin-top: 16px;
}

.GridTransactions-touchedExpandOptionsAction{
    background-color: rgba(255,170,102, 0.8);
    border: 1px solid rgba(255,170,102, 0.8);

  &:hover{
    background-color: rgba(255,170,102, 0.6);
    border: 1px solid rgba(255,170,102, 0.6);
  }

  &:active{
    background-color: rgba(255,170,102, 1);
    border: 1px solid rgba(255,170,102, 1);
  }
}
