.newTerminal--wrapper .row-text-input-text {
  justify-content: space-between;
  p.addSets-form-text {
    width: 100%;
    // &.last {
    //   text-align: right;
    // }
  }
  .newTerminal---additional-media-input-class {
    width: 90%;
    margin-left: 0;
  }
}

@media (max-width: 720px) {
  .newTerminal--columnFieldsGridForMobile{
    .newTerminal--card-row {
      flex-direction: column;
    }
  }
}

.newTerminal-input {
  width: 100%;
  max-width: 300px;
}
.dropdown-profiles-wrapper-create {
  margin-left: 10px;
}
.newTerminal--wrapper {
  // padding-left: 15px;
  // padding-right: 15px;
  // width: 100%;
  // max-width: 800px;
  // position: relative;
  // margin: 10px auto 0;
  p {
    margin-bottom: 0;
    font-size: 14px;
    &.form-text {
      min-width: 177px;
      text-align: right;
    }
  }
  .k-datepicker {
    width: 50%;
    margin-left: 10px;
  }
}
.mt-10 {
  margin-top: 10px;
}
.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.newTerminal--warning-text {
  margin-left: 10px;
}
.newTerminal--header-input {
  margin-left: 10px;
}
.newTerminal--btn-save {
  display: flex;
  align-self: end;
}
.newTerminal--header-row {
  justify-content: space-between;
}
.newTerminal--card-row {
  flex-wrap: nowrap;
}
// .newTerminal--card {
//   &.w-50 {
//     width: 50%;
//   }
// }

@media (max-width: 400px) {
  .dropdown-profiles-wrapper-create {
    margin-left: 0;
    width: 100%;
  }
  .newTerminal--wrapper {
    .k-datepicker {
      width: 100%;
      margin-left: 0;
    }
  }
  .newTerminal--card-row {
    flex-direction: column;
    p {
      font-size: 14px;
    }
    .ant-select {
      width: 100% !important;
      margin-left: 0;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .newTerminal--btn-save {
    margin-top: 10px;
  }
  .btn-add-toggler {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  .newTerminal--wrapper p.form-text {
    text-align: left;
    min-width: 100%;
  }
}

