.ServiceUserAccessGrid-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 740px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ServiceUserAccessGrid-fieldContent{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px 12px -4px -12px;

  @media (max-width: 1460px) {
    flex: 0 0 540px;
  }

  @media (max-width: 740px) {
    width: 100%;
    flex: 1 1 auto;
    margin: -4px 0 4px 0;
  }
}

.ServiceUserAccessGrid-fieldRow{
  display: flex;
  align-items: center;
  padding: 4px 12px;

  @media (max-width: 740px) {
    flex: 1 1 100%;
    width: 100%;
    padding: 4px 0;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ServiceUserAccessGrid-label{
  width: 56px;
  flex: 0 0 56px;

  @media (max-width: 460px) {
    flex: 1 1 auto;
    width: auto;
  }
}

.ServiceUserAccessGrid-longLabel{
  width: 90px;
  flex: 0 0 90px;

  @media (max-width: 460px) {
    flex: 1 1 auto;
    width: auto;
  }
}

.ServiceUserAccessGrid-select{
  width: 486px;
  flex: 0 0 486px;

  @media (max-width: 740px) {
    flex: 1 1 100%;
    width: 100%;
  }
}

.ServiceUserAccessGrid-historyAction{
  flex: 0 0 100%;
  width: 100%;
  margin-top: 16px;
}
