.basegrid-sortable-item {
  //line-height: 68px;
  height: 70px;
  text-align: center;
  outline: none;
  border: 1px solid #fff;
  cursor: move;
  display: flex;
  align-items: center;
  padding: 0 10px;
  // background: #bfe7f9;
  background: lightgrey;
  width: 100%;
  max-width: 400px;
  &.active {
    background: #27aceb;
    border-color: #27aceb;
  }
  .ant-switch{
    min-width: 66px!important;
  }
}
.basegrid-sortable-drag{
  width: 45px;
}
.basegrid-sortable-item-span-wrapper {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: 12px;
    word-wrap: break-word;

  }
  .ant-checkbox-inner{
    width: 45px!important;
    height: 45px!important;
    &::after{
      top: 33%;
      left: 22%;
      width: 15px;
      height: 35px;
    }
  }

}

.basegrid-sortable-item-switch {
  margin-left: 5px;

}

.basegrid-sortable-item-switch .ant-switch{
  min-width: 66px!important;
}

// .k-dialog-wrapper .k-dialog  {
//   .k-window-content, .k-prompt-container {

//   }
// }
