.slide-elem-wrapper {
  position: relative;
  .k-loading-mask {
    height: 100% !important;
  }
}
.modal-slider-icon {
  font-size: 15px !important;
  &.white {
    color: #ffffff;
  }
  &.black {
    color: #000000;
  }
}

.sliderElem-info {
  position: absolute;
  padding: 16px;
  background: transparent;
  top: 40px;
  width: 100%;
  height: 0;
  z-index: 10;
  transition: ease-in 0.3s;
  .sliderElem-info-abs {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #1890ff;
    right: 9px;
    top: 0;
  }
  .sliderElem-info-row {
    width: 100%;
    height: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-top: 10px;
    &.first {
      margin-top: 0;
    }
    span {
      &.bold {
        font-weight: bold;
      }
      &:last-child {
        margin-left: 3px;
      }
    }
  }
  &.full {
    height: 120px;
    background: rgba(34, 34, 34, 0.85);
    .sliderElem-info-row {
      width: 100%;
      height: auto;
      transition: ease-in 0.5s;
    }
  }
}
