.ServiceLimitsGrid{
  display: flex;
  padding: 8px;
}

.AddLimitsModal-label {
  margin-bottom: 4px;
}

.AddLimitsModal-selectWrapper{
  margin-bottom: 8px;
}

.AddLimitsModal-input{
  width: 100%;
}

.AddLimitsModal-alert{
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddLimitsModal-changeAlert{
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
