.dealerCommission-row{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.GridDropdown{
  width: 100%;
}

.dealerCommission-label{
  width: 100px;
}


.dealerCommission-select{
  width: 620px;

  @media (max-width: 800px) {
    width: calc(100% - 100px);
  }
}

.dealerCommission-toolbar{
  @media (max-width: 992px) {
    margin-top: 8px;
  }
}
