.BlockedTransactionsPage{
  padding: 16px;
}

.BlockedTransactionsPage-sendReport{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.BlockedTransactionsPage-sendReportSubmitAction{
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}

.BlockedTransactionsPage-sendReportFieldsItem{
  display: flex;
  flex-wrap: wrap;
  margin: -16px -16px 0;
  width: 572px;

  @media (max-width: 600px) {
    width: 100%;
    margin: -4px -4px 0;

    &:last-of-type{
      margin: 0 -4px 4px;
    }
  }
}

.BlockedTransactionsPage-sendReportFieldsItemChild{
  display: flex;
  align-items: center;
  padding: 16px;

  @media (max-width: 600px) {
    padding: 4px;
  }
}

.BlockedTransactionsPage-dateError{
  flex: 0 0 100%;
  color: red;
  box-sizing: border-box;
  padding-left: 16px;
  padding-bottom: 16px;
  margin-top: -16px;
  font-size: 12px;
}

.BlockedTransactionsPage-label{
  width: 80px;
  text-align: left;
}

.BlockedTransactionsPage-emailField{
  width: 460px !important;

  @media (max-width: 600px) {
    width: 72vw !important;
  }

  @media (max-width: 476px) {
    width: 64vw !important;
  }
}

.BlockedTransactionsPage-sendReportActions{
  display: flex;
  flex-wrap: wrap;
  button{
    margin: 0 8px 8px 0!important;
  }
}
