.StepServiceCreationOwnerCommissions {
  .StepServiceCreation-serviceIdRow {
    margin-bottom: 16px;
  }

  .ChangeDealerCommissions-wrapp{
    @media (min-width: 600px) {
      .GridDropdown-content{
        height: 260px;
      }
    }
  }
}

.StepServiceCreationOwnerCommissions-action{
  margin-top: 16px;
}
