.WorkWithDealers-content{
  padding-top: 24px;
}

.WorkWithDealers-toolbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 580px) {
    flex-direction: column;
    align-items: flex-start;
  }
  padding: 0 16px 16px;
}

.WorkWithDealers-row{

  .k-grid{
    overflow-x: auto;
    max-height: calc(100vh - 214px);
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
}

.WorkWithDealers-btnOnSave{
  margin-left: auto !important;

  @media (max-width: 580px) {
    margin: initial !important;
  }
}

.GroupMembership-userLabel {
  margin-right: 12px;
}

.GroupMembership-headerUserRow{
  flex-wrap: nowrap;
  width: 300px;
  @media (max-width: 580px) {
    margin-bottom: 8px;
  }
}

.WorkWithDealers-headerUserRow{
  flex-wrap: nowrap;
  width: 560px !important;

  .WorkWithDealers-userLabel {
    margin-right: 12px;
  }

  @media (max-width: 580px) {
    margin-bottom: 8px;
    flex-direction: column;
    width: 100% !important;
    justify-content: flex-start;
    align-items: flex-start;

    .WorkWithDealers-userLabel {
      margin-right: 0;
      margin-bottom: 4px;
      text-align: left;
    }
  }
}
