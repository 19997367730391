.GridAllEncashments-toolbar{
  .ant-select{
    z-index: 1;
  }
}

.GridAllEncashments-actionRow{
  display: flex;
  margin-top: 10px;

  &>div:first-of-type{
    margin-right: 8px;
  }
}

.ModalEncashmentCheck{
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  &-label{
    width: 120px;
  }
  &-input{
    position: relative;
    width: 100%;
  }
  &-err-msg{
    position: absolute;
    top:100%;
    left:0;
    font-size: 13px;
    color: red;
  }
}
.input-err{
  border: 1px solid red;
}
