@import "../../styles/config.scss";

.page-login {
    .ant-message-notice {
      .ant-message-notice-content {
        background-color: #90ee90 !important;
      }
    }
  &.ant-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    background-color: $white;
    // background: #3498db;
    // background-color: #282c34;
  }

  h2 {
    margin-bottom: $basic-offset;
    text-align: center;
    font-weight: $font-weight-light;
    font-size: 2rem;
    margin-top: $basic-offset;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    &.ant-layout-header {
      background-color: $white;

        img {
          margin-left: $basic-offset;
          max-height: 42px;
          max-width: 105px;
          flex: 1 0 auto;
        }
    }

  }
  .ant-form {
    button {
      &[type="submit"] {
        font-weight: 400;
        background: $blue;
        border: none;
        color: $white;
        // cursor: pointer;
        transition: box-shadow 0.4s ease;
        font-family: $font-family;
        font-size: 17px;
        height: 40px;
        margin: 5px 36px 0;
        border-radius: 0.1rem;
        outline: 0;
        width: 260px;
      }
    }
  }

  .ant-input {
    width: auto;
    display: block;
    border: 1px solid #bbb;
    padding: 0 0 0 42px;
    font-size: 17px;
    font-weight: $font-weight-light;
    height: 40px;
    margin: auto 40px 20px;
    border-radius: 0.1rem;
    width: 260px;
    outline: 0;

    &:not(:first-child) {
      padding-left: 0;
    }
  }

  .k-icon {
    color: #34495e;
    position: absolute;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 38px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
  }

  .ant-input-affix-wrapper {
    .ant-input-prefix {
      color: #34495e;
      position: absolute;
      top: 1px;
      left: 0;
    }
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 0;
  }

  .login-submit {
    font-weight: $font-weight-normal;
    background: $blue;
    border: none;
    color: $white;
    transition: box-shadow 0.4s ease;
    height: 40px;
    margin: auto 31px 1.2rem 40px;
    border-radius: 0.1rem;
    outline: 0;
    font-size: 17px;
  }

  form {
    button {
      &:hover {
        box-shadow: none;
      }
    }

    span {
      &.button {
        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  .ant-form-explain,
  .ant-form-extra {
    position: absolute;
    bottom: 0;
    padding-left: 42px;
  }

  .ant-input-affix-wrapper {
    .ant-input {
      &:not(:first-child) {
        padding-left: 42px;
        margin-top: 5px;
      }
    }
  }

  .ant-menu {
    float: right;
    line-height: 64px;
    display: flex;
    justify-content: space-between;

    .ant-menu-item {
      line-height: 40px;

      button {
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
      }

      &.hover {
        background-color: $grey;
      }

    }
  }

  main {
    display: flex;
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    button,
    span.button {
      font-family: $font-family;
      font-size: 17px;
      font-weight: $font-weight-light;
      height: 40px;
      margin: auto 30px 0 30px;
      border-radius: $basic-radius;
      outline: 0;
    }

  }

  .login-carousel-wrapper {
    position: relative;
    margin: auto;
    background-color: $grey;
    width: 350px;
    min-height: 310px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    padding: $basic-offset;
    .christmas-hat-img {
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: 999;
    }

    &:after {
      content: "";
      border-radius: $basic-radius;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        to left,
        $teal 0%,
        $teal,
        $purple 20%,
        $purple 40%,
        $cyan 40%,
        $cyan 60%,
        $red 60%,
        $red 80%,
        $yellow 80%,
        $yellow 100%
      );
      height: 2px;
    }

  }

  footer {
    &.ant-layout-footer {
      display: flex;
      flex-direction: row;
      min-height: 40px;
      padding: $basic-offset;
      align-items: center;
      justify-content: space-between;
      background: $white;

      p {
        margin: 0;
      }

      span {
        color: $red;
        margin-left: $basic-offset;
      }

      .ant-row-flex {
        width: 100%;
        align-items: center;

        .ant-col {

          &:first-child {
            color: #dbdbdb;
          }

          &:last-child {
            text-align: right;
          }

          span {
            color: #656565;
          }

          .k-icon {
            position: relative;
            left: 0;
            display: inline-block;
            margin-left: 2px;
            color: $red;
            width: 19px;
            height: 19px;
            line-height: 17px;
            top: 0;
          }
        }
      }
    }
  }

  .error-message {
    margin: auto $basic-offset;
    font-weight: $font-weight-normal;
    text-align: right;
    color: $red;
  }

  .ant-alert {
    &.ant-alert-error {
      margin: auto (-$basic-offset) (-$basic-offset);
    }
  }
  .dialog-login-wrapper {
    position: relative;
  }
}

@media (max-width: 767px) {
  .page-login {

    .ant-input {
      margin: 0 20px 20px 16px;
    }

    .login-carousel-wrapper {
      width: 310px;
    }

    .k-icon {
      left: 18px;
    }

    .ant-form {
      button {
        &[type="submit"] {
          margin: 5px 20px 5px 16px;
        }
      }
    }

    header {
      &.ant-layout-header {
          img {
            margin-left: 0;
          }
      }
    }

    footer {
      &.ant-layout-footer {
        p {
          text-align: center;
        }

        .ant-row-flex {
          flex-direction: column;

          .ant-col {
            text-align: center;
          }
        }
      }
    }
  }
}
