.CardRoutingUpdateModal{
  width: 720px !important;
  top: 0 !important;

  .CustomInput-alarmMessage{
    font-size: 10px !important;
  }

  @media (max-width: 764px) {
    .CardRoutingUpdateModal{
      width: 100% !important;
    }
  }

  .GridDropdown-contentWrapper{
    left: 0;
  }
}

.CardRoutingUpdateModal-label{
  flex: 0 0 150px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;

  @media (max-width: 520px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}

.CardRoutingUpdateModal-input{
  flex: 1 1 auto;

  @media (max-width: 520px) {
    width: 100%;
  }
}

.CardRoutingUpdateModal-inputRow{
  display: flex;
  align-items: center;
  margin-bottom: 22px;


  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.CardRoutingUpdateModal-flexRow{
  display: flex;

  &>div{
    flex: 1 1 50%;
  }

  @media (max-width: 764px) {
    flex-direction: column;

    &>div{
      flex: 1 1 auto;
    }
  }
}

.CardRoutingUpdateModal-shortRow{
  width: 50%;

  @media (max-width: 764px) {
    width: 100%;
  }
}

.CardRoutingUpdateModal-shortSelect{

  .GridDropdown-content{
    height: 280px;
  }
}
