.ManagementErrorCodesGrid-toolbar{
  margin: -4px;
  display: flex;
  flex-wrap: wrap;
}

.ManagementErrorCodesGrid-buttonWrapper{
  padding: 4px;

  button{
    margin: 0;
  }
}

