.ServiceAdditionalProps-toolbar{
  @media(max-width: 992px){
    margin-top: 8px;
  }

  button {
    margin-left: 0;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  input {
    max-width: 300px;
  }
}

.ServiceAdditionalProps-serviceInfo{
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ServiceAdditionalProps-label{
  width: 120px;
  flex: 0 0 120px;
}

.UpdatePropsModal{
  width: 640px !important;
}

.UpdatePropsModal-row{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  button {
    margin-left: 0;
  }

  .GridDropdown-contentWrapper{
    left: 0;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UpdatePropsModal-label{
  width: 130px;
  flex: 0 0 130px;

  @media (max-width: 640px) {
    width: auto;
    text-align: left;
    margin-bottom: 6px;
    flex: 1 1 auto;
  }
}

.UpdatePropsModal-wideLabel{
  width: 170px;
  box-sizing: border-box;
  padding-right: 4px;
}

.UpdatePropsModal-error{
  color: red;
  text-align: center;
}

.UpdatePropsModal-select{
  width: 486px;
  flex: 0 1 486px;

  @media (max-width: 640px) {
    flex: 1 1 auto;
    width: 100%;
  }
}
