.input-user-wrapper {
  width: 100%;
  padding-top: 20px;
  padding-left: 38px;
  .input-user-subwrapper {
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    input {
      margin-left: 5px;
    }
  }
}
.restrictions-wrapper {
  padding: 20px;
  display: flex;
  height: calc(100% - 50px);
  justify-content: space-evenly;

  .sub-grid-wrapper {
    height: 100%;
    &.left {
      width: 18%;
    }
    &.right {
      width: 78%;
    }
  }
  .admin-grids {
    height: 100% !important;
    width: 100%;
  }
}

// @media (max-width: 992px) {
//   .restrictions-wrapper {

//     // .admin-grids {
//     //   width: 350px;
//     // }
//     .sub-grid-wrapper {
//       // &.left, &.right {
//       //   width: 100%;
//       // }
//     }
//   }
// }
@media (max-width: 767px) {
  .input-user-wrapper {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .restrictions-wrapper {
    flex-direction: column;
    align-items: center;
    height: auto;
    .sub-grid-wrapper {
      height: 350px;
      &.left, &.right  {
        width: 100%;
      }
      &.right {
        margin-top: 20px;
      }
    }
    .admin-grids {
      // width: 440px;
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .restrictions-wrapper {
    .admin-grids {
      width: 100%;
    }
  }
}

@media (max-width: 430px) {
  .restrictions-wrapper {
    padding: 10px;
  }
}
