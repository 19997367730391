@import './config.scss';

html, body, #root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: Tahoma, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Удаление Kendo - костыля, который мешает скролить:
  * после закрытия попапа не убирает
  * инлайн стиль touch-action: none;
  */
  touch-action: auto !important;
  background-color: #f0f2f5;
}

canvas {
  display: block;
}

ul, ol {
  list-style: none;
}

.k-loading-mask {
  z-index: 9999992 !important;
}


@import './kendo.scss';