.gridAdminStockPhotos-toolbar-row {
  &.space-between {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  &.mt-10 {
    margin-top: 10px;
  }
  .sub-row {
    width: 50%;
    display: flex;
    align-items: center;
    // &.left {
    //   justify-content: space-between;
    // }
    &.right {
      // justify-content: flex-end;
      margin-left: 10px;
    }
    p {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
  .block-with-photos {
    background: #1890FF;
    padding: 6px 14px;
    font-size: 16px;
    color: #ffffff;
    margin-left: 10px;
    border-radius: 43px;
  }
  .info-block {
    width: 100%;
  }
  .info-wrapper {
    width: 220px;
    min-width: 50%;
    height: 34px;
    border-radius: 43px;
    padding: 3px 3px 3px 10px;
    display: flex;
    align-items: center;
    color: #ffffff;
    justify-content: space-between;
    &:last-child {
      margin-top: 10px;
    }
    &.second {
      margin-left: 10px;
    }
    &.brandedByStandart {
      background: #31EE5A;
    }
    &.notBranded {
      background: #FF7144;
      span.third {
        background-color: #FF7144;
        color: #ffffff;
      }
    }
    &.branded {
     background: #FFB444;
    }
    &.underConsideration {
      color: #565656;
      background-color: #fff;
      border: 1px solid #C4C4C4;
    }
    span {
      &.second {
        font-weight: bold;
      }
      &.third {
        margin-left: 3px;
        width: 43px;
        background-color: #ffffff;
        color: #565656;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 43px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1200px) {
  .gridAdminStockPhotos-toolbar-row .sub-row p {
    font-size: 14px;
  }
  .gridAdminStockPhotos-toolbar-row .info-wrapper {
    min-width: auto;
    width: auto;
  }
  
  .gridAdminStockPhotos-toolbar-row.space-between {
    flex-direction: column;
    .sub-row {
      width: 100%;
      &.right {
        margin-top: 15px;
        margin-left: 0;
      }
    }
  }
}

@media(max-width: 576px) {
  .gridAdminStockPhotos-toolbar-row.space-between {
    .sub-row {
      &.left {
        display: flex;
        justify-content: center;
        .block-with-photos {
          max-width: 200px;
        }
      }
      &.right {
        flex-direction: column;
      }
    }
  }
  .gridAdminStockPhotos-toolbar-row .info-wrapper {
    width: 100%;
    &.second {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}