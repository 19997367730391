.UpdateMenuProfileModal{

}

.UpdateMenuProfileModal-inputLabel{
  margin-bottom: 4px;
}

.UpdateMenuProfileModal-deleteContent{
  text-align: center;
}
