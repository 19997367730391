.FishkaServiceGrid-toolbar {
  flex: 1 1 auto;

  @media (max-width: 992px) {
    margin-top: 8px;
  }
}

.AddCashbackDialog{
  width: 640px !important;

  .ant-modal-content{
    width: 640px;
  }

  @media(max-width: 650px){
    width: 100% !important;

    .ant-modal-content{
      width: 100%;
    }
  }
}

.AddCashbackDialog-row{
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &:last-child{
    margin-bottom: 0;
  }

  .k-button{
    margin: 0
  }
}

.AddCashbackDialog-numberInput{
  width: 100%;
}

.AddCashbackDialog-errorMessage{
  color: red;
  text-align: center;
}
