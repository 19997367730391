.SUOGrid-toolbar{
  display: flex;
  margin: -8px;
  flex-wrap: wrap;

  &>div{
    padding: 8px;
  }
}

.AddSUODialog-inputRow{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.AddSUODialog-label {
  width: 140px;
}

.AddSUODialog-longLabel{
  width: 240px
}

.AddSUODialog-input{
  flex: 1 1 auto;
}

.AddSUODialog{
  width: 740px !important;
}

.AddSUODialog-inputsRow{
  display: flex;
  align-items: center;
  margin: -8px -8px 8px;

  &>div{
    padding: 8px;
  }
}
