.SupplierCommissionsForm{
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
&-label{
  width: 120px;
}
  &-input{
    @media (max-width: 772px) {
      width: 100%;
    }
  }
}
