.CommissionDetails{
  padding: 32px;
  height: 110vh;
  overflow: auto;
}

.CopyCommission-radioGroup{
  margin-bottom: 48px;
}

.CommissionDetails-radio{
  margin-bottom: 8px;

  &:last-child{
    margin-bottom: 0;
  }
}

.CommissionDetails-selects{
  display: flex;
  flex-wrap: wrap;
}

.CommissionDetails-selectsRow{
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:last-child{
    margin-bottom: 8px;
  }
}

.CommissionDetails-iconRow{
  display: flex;
  justify-content: center;
}

.CommissionDetails-firstOrderRow {
  order: 0;
}

.CommissionDetails-secondOrderRow {
  order: 1;
}
.CommissionDetails-thirdOrderRow {
  order: 2;
}

.CommissionDetails-errorMessage{
  color: red;
  font-size: 14px;
  position: absolute;
  bottom: -20px;
  left: 6px;
}

.CommissionDetails-icon{
  width: 16px;
}

@media (max-width: 992px) {
  .CommissionDetails-selectsRow {
    margin-bottom: 48px;
    &:last-child{
      margin-bottom: 16px;
    }
  }

  .CommissionDetails-titleRow{
    margin-bottom: 4px
  }

  .CommissionDetails-icon{
    margin-top: 8px;
  }
}

.OldCommissionDeleteRow{
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
