.GraphicWorkingHours-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  &:last-child {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
  }

  .k-timepicker {
    width: 100%;
  }

}

.GraphicWorkingHours-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

@media (max-width: 991px) {
  .GraphicWorkingHours-row {
    margin-top: 0;
    border-bottom: 1px solid #cecece;
    &:last-child {
      margin-bottom: 0;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    .k-timepicker {
      margin-top: 10px;
      .k-input {
        font-size: 13px !important;
      }
    }
    .modalGraphicWH--divider {
      display: none;
    }
    .ant-checkbox-wrapper {
      margin-top: 10px;
    }
  }
}
