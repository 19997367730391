.profile-wrapper {
  background: #ececec;
  // padding: 30px;
  height: 100%;
  padding-top: 15px;
  overflow-y: auto;
  p {
    margin-bottom: 0;
  }
  .profile-card {
    position: relative;
    margin: auto 25%;
  }
}
.card-trustedDevices-warning {
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .profile-wrapper {
    padding: 5px;
    .profile-card {
      margin: 0 auto;
      padding: 0;
    }
    h3,
    h4,
    p {
      margin-bottom: 1.25rem;
    }
    .ant-card-body {
      padding: 7px;
    }
  }
}
