.SuppliersUsers{
  padding: 16px;

  &>div:first-of-type{
    margin-bottom: 16px;
  }

  .ant-card-body{
    padding: 0 !important;
    height: 36vh;
    overflow: hidden;
  }
}

.AddSupplierModal{
  width: 600px !important;
}
