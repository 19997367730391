.UserServicesAccess-toolbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UserServicesAccess-userInfo{
  display: flex;
  align-items: center;
  margin-right: 32px;

  @media (max-width: 720px) {
    margin-right: 0;
    margin-bottom: 8px;
  }

  @media (max-width: 380px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UserServicesAccess-label{
  flex: 0 0 120px;
  width: 120px;

  @media (max-width: 380px) {
    flex: 0 0 auto;
    width: auto;
  }
}

.UserServicesAccess-actions{
  @media (max-width: 380px) {
    &>button{
      margin: 0 0 4px 0;
      display: block;
    }
  }
}

.UserServicesAccess-error{
  color: red;
  width: 100%;
  flex: 0 0 100%;
  margin-top: 4px;
}
