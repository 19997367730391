.SupplierCommissions{
  z-index: 0;
  width: 100%;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.SupplierCommissions-cardContent{
  flex: 1 1 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;

  @media (max-width: 800px) {
    margin: 0;
    flex: 1 1 auto;
    width: auto;

    &:first-of-type{
      margin-bottom: 16px;
    }
  }
}

.SupplierCommissions-label{
  flex: 0 0 116px;
  width: 116px;

  @media (max-width: 800px) {
    flex: 1 1 auto;
    width: auto;
  }
}

.SupplierCommissions-inputRow {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.SupplierCommissions-input{
  width: 100%
}
