.cellPhotos-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.cell-photo-wrap {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  &.blue {
    background: #1890FF;
  }
  &.red {
    background: #FF7144;
  }
}
