.ServiceMainProps{
  padding: 32px;

  @media (min-width: 993px) {
    .GridDropdown-content{
      height: 396px;
    }
  }

  .CustomInput-alarmMessage{
    font-size: 10px;
  }
}

.TopAlignedGridDropdown {


  @media (min-width: 993px) {
    .GridDropdown-contentWrapper{
      top: -396px;
    }
  }
}

.ServiceMainProps-input{
  width: 100%
}

.ServiceMainProps-flexRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ServiceMainProps-row{
  .ServiceMainProps-input {
    width: 460px;

    @media (max-width:800px) {
      width: 100%;
    }
  }
}

.ServiceMainProps-selectWithCheckboxRow {
  @media (max-width:800px) {
    &>div:first-of-type{
      flex: 1 1 auto;
    }

    &>div:last-of-type{
      flex: 0 0  16px;
    }
  }
}

.ServiceMainProps-narrowRow{
  .ServiceMainProps-input {
    width: 300px;
    flex: 0 0 300px;

    @media (max-width:800px) {
      width: auto;
      flex: 0 0 auto;
    }
  }
}

.ServiceMainProps-exNarrowRow{
  .ServiceMainProps-input {
    width: 120px;
    flex: 0 0 120px;

    @media (max-width:800px) {
      width: auto;
      flex: 0 0 auto;
    }
  }
}



.ServiceMainProps-inputRow {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ServiceMainProps-label {
  flex: 0 0 160px;
  width: 160px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 8px;

  @media (max-width: 800px) {
    flex: 1 1 auto;
    width: auto;
    margin-bottom: 4px;
  }
}

.ServiceMainProps-narrowGap{
  margin-right: 8px ;
}

.ServiceMainProps-middleGap{
  margin-right: 24px ;
}
