.DealerCommissionsTerminalsGrid-searchContent{
  display: flex;
  align-items: center;

  @media (max-width: 760px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.DealerCommissionsTerminalsGrid-actions{
  margin-top: 24px;
  margin-bottom: 8px;
}

.DealerCommissionsTerminalsGrid-selects{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px -10px;
  max-width: 1150px;

  @media (max-width: 1480px) {
    max-width: 630px;
  }
}

.DealerCommissionsTerminalsGrid-selectRow{
  display: flex;
  align-items: center;
  padding: 4px 10px;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.DealerCommissionsTerminalsGrid-selectLabel{
  width: 72px;

  @media (max-width: 760px) {
    margin-bottom: 4px;
  }
}

.DealerCommissionsTerminalsGrid-select{
  width: 480px;

  @media (max-width: 520px) {
    width: 100%;
  }
}

.DealerCommissionsTerminalsGrid-searchAction{
  flex: 0 0 auto;
  padding-left: 24px;

  @media (max-width: 760px) {
    padding-left: 0;
    padding-top: 12px;
  }
}

.DealerCommissionsTerminalsUpdateAuthorizationLimitModal{
  width: 600px !important;
}
