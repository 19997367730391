.addSets--div-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mt-10 {
  margin-top: 10px;
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.newTerminal--header-input {
  margin-left: 10px;
}

.newTerminal--btn-save {
  display: flex;
  align-self: end;
}

.newTerminal--header-row {
  justify-content: space-between;
}

.newTerminal--card-row {
  flex-wrap: nowrap;
}


.addSets--graphics-row {
  width: 100%;
  display: flex;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
}

.newTerminal--wrapper .newTerminal--card-row p.addSets-form-text {
  text-align: left;
}

.addSets--radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  .ant-radio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .ant-radio-input {
      margin-top: 6px;
      margin-right: 5px;
    }
  }
}

.addSets-checkbox-wrapper {
  display: flex;
  // width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  .ant-checkbox + span {
    margin-top: -3px;
    padding-left: 0;
  }
}

.addSets-time-picker {
  margin-left: 10px;
  // width: 70% !important;
}

.addSets--lease-contract {
  display: flex;
  align-items: center;
  width: 100%;
  .ant-input-number {
    width: 80%;
  }
  .ant-select {
    width: 100%;
    margin-left: 10px;
  }
}


.newTerminal--wrapper .addSets-date-picker-wrap {
  .k-datepicker {
    width: 100%;
  }
}

@media (max-width: 992px) {
 .addSet-main-col  {
    margin-left: 0 !important;
  }
  .addSets--radio {
    padding-left: 0;
    .ant-radio-wrapper {
      span {
        &:last-child {
          font-size: 12px;
        }
      }
    }
  }
  .newTerminal--card-row.spec-row {
    flex-direction: column;
    margin-top: 10px;
    .addSets--lease-contract {
      margin-top: 10px;
    }
    .k-timepicker {
      width: 100%;
    }
  }
  .addSet-main-col {
    .ant-input-number {
      width: 100%;
    }
    p {
      min-width: 177px;
    }
  }
}

@media (max-width: 767px) {
  .addSet-main-col {
    margin-left: 0;
  }
  .addSets-mob-checkbox-row {
    align-items: flex-start;
  }
  .addSets--lease-contract {
    flex-direction: column;
    p {
      width: 100%;
    }
  }
}

@media (max-width:400px) {
  .newTerminal--card-row .addSets--lease-contract .ant-select {
    margin-right: 0;
  }
}

.addSets-timepickers-block{
  display: flex;
  align-items: center;
}

@media (max-width: 400px) {
  .dropdown-profiles-wrapper-create {
    margin-left: 0;
    width: 100%;
  }
  .newTerminal--wrapper {
    .k-datepicker {
      width: 100%;
      margin-left: 0;
    }
  }
  .newTerminal--card-row {
    flex-direction: column;
    p {
      font-size: 14px;
    }
    .ant-select {
      width: 100% !important;
      margin-left: 0;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .newTerminal--btn-save {
    margin-top: 10px;
  }
  .btn-add-toggler {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  .newTerminal--wrapper p.form-text {
    text-align: left;
    min-width: 100%;
  }
}
