.CardRoutingGrid-searchContent{
  display: flex;
  align-items: center;

  @media (max-width: 760px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.CardRoutingGrid-searchAction{
  flex: 0 0 auto;
  margin-top: 16px;

  @media (max-width: 760px) {
    padding-left: 0;
    padding-top: 0;
  }
}

.CardRoutingGrid-selects{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px -10px;
  max-width: 1256px;

  @media (max-width: 1480px) {
    max-width: 662px;
  }
}

.CardRoutingGrid-action{
  margin-top: 24px;
  display: flex;
  margin: 8px -6px -4px;
  flex-wrap: wrap;

  &>div{
    padding: 4px 6px;
  }
}

.CardRoutingGrid-selectRow{
  display: flex;
  align-items: center;
  padding: 4px 10px;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.CardRoutingGrid-selectLabel{
  width: 90px;

  @media (max-width: 1482px) {
    width: 144px;
  }

  @media (max-width: 760px) {
    margin-bottom: 4px;
  }
}

.CardRoutingGrid-selectWideLabel{
  width: 144px;

  @media (max-width: 760px) {
    margin-bottom: 4px;
  }
}


.CardRoutingGrid-select{
  width: 480px;

  @media (max-width: 520px) {
    width: 100%;
  }
}


.CardRoutingGrid-actions{
  margin-top: 24px;
  margin-bottom: 8px;
}
