@charset "UTF-8";
.ExternalOutgoingPaymentsContainer {
  padding: 16px; }

.ExternalOutgoingPayments-select {
  width: 220px; }
  @media (max-width: 860px) {
    .ExternalOutgoingPayments-select {
      width: 240px; } }

.ExternalOutgoingPayments-fieldRow {
  display: flex;
  align-items: center; }
  @media (max-width: 400px) {
    .ExternalOutgoingPayments-fieldRow {
      flex-direction: column;
      align-items: flex-start; } }

.ExternalOutgoingPayments-label {
  width: 130px; }

.ExternalOutgoingPayments-shortLabel {
  width: 72px; }
  @media (max-width: 860px) {
    .ExternalOutgoingPayments-shortLabel {
      width: 130px; } }

.ExternalOutgoingPayments-toolbar {
  display: flex;
  align-items: center; }
  .ExternalOutgoingPayments-toolbar button {
    margin: 0 !important; }
  .ExternalOutgoingPayments-toolbar > div:last-of-type {
    margin-left: 32px;
    margin-right: 40px; }
  @media (max-width: 860px) {
    .ExternalOutgoingPayments-toolbar {
      flex-direction: column;
      align-items: flex-start; }
      .ExternalOutgoingPayments-toolbar > div:last-of-type {
        margin: 8px 0 16px; } }

.ExternalOutgoingPayments-input {
  width: 240px; }

.AddPaymentByBigTable {
  padding: 32px; }
  .AddPaymentByBigTable .GridDropdown-contentWrapper {
    left: 0; }

.AddPaymentByBigTable-card {
  max-width: 760px;
  width: 100%;
  margin-bottom: 32px;
  position: relative; }
  @media (max-width: 800px) {
    .AddPaymentByBigTable-card {
      max-width: initial; } }

.AddPaymentByBigTable-ButtonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px; }

.AddPaymentByBigTable-inputRow {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  width: 100%; }
  @media (max-width: 800px) {
    .AddPaymentByBigTable-inputRow {
      flex-direction: column;
      align-items: flex-start; } }

.AddPaymentByBigTable-label {
  flex: 0 0 140px;
  width: 140px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 8px; }
  @media (max-width: 800px) {
    .AddPaymentByBigTable-label {
      flex: 1 1 auto;
      width: auto;
      margin-bottom: 4px; } }

.AddPaymentByBigTable-input {
  width: 100%; }
  .AddPaymentByBigTable-input textArea {
    resize: vertical !important;
    box-sizing: border-box;
    /* Чтобы padding учитывался в высоте */
    width: 100%;
    /* Ширина textarea */
    min-height: 50px;
    /* Минимальная высота */
    overflow-y: hidden;
    /* Скрываем вертикальный скроллбар */ }
  .AddPaymentByBigTable-input .CustomInput-alarmMessage {
    bottom: -30px !important;
    top: auto !important; }

.iban-button {
  margin-bottom: 32px; }

.AddPaymentByBigTable-rowWithAction {
  display: flex;
  align-items: center; }
  .AddPaymentByBigTable-rowWithAction > .AddPaymentByBigTable-action {
    flex: 0 0 auto;
    margin-bottom: 32px; }
    .AddPaymentByBigTable-rowWithAction > .AddPaymentByBigTable-action .AddPaymentByBigTable-inputRow {
      margin-bottom: 0; }
  .AddPaymentByBigTable-rowWithAction > .AddPaymentByBigTable-inputRow {
    flex: 1 1 auto;
    margin-right: 32px; }
  @media (max-width: 800px) {
    .AddPaymentByBigTable-rowWithAction {
      align-items: flex-end; } }

.AddPaymentTypeByBigTableModal .GridDropdown-contentWrapper {
  left: 0; }

.AddPaymentTypeByBigTableModal-inputRow .AddPaymentByBigTable-label {
  flex: 0 0 110px;
  width: 110px; }
  @media (max-width: 800px) {
    .AddPaymentTypeByBigTableModal-inputRow .AddPaymentByBigTable-label {
      flex: 1 1 auto;
      width: auto; } }

.AddPaymentTypeByBigTableModal {
  width: 640px !important; }

.AddPaymentTypeByBigTableModal-error {
  color: red;
  font-size: 14px; }

.AddPaymentByBigTable-cardContent {
  z-index: 0;
  width: 100%;
  display: flex; }
  @media (max-width: 800px) {
    .AddPaymentByBigTable-cardContent {
      flex-direction: column; } }

.AddPaymentByBigTable-innerCard {
  flex: 1 1 calc(50% - 16px);
  margin: 0 8px; }
  @media (max-width: 800px) {
    .AddPaymentByBigTable-innerCard {
      margin: 0;
      flex: 1 1 auto; }
      .AddPaymentByBigTable-innerCard:first-of-type {
        margin-bottom: 16px; } }

.AddPaymentByBigTable-shortLabel {
  flex: 0 0 116px;
  width: 116px; }
  @media (max-width: 800px) {
    .AddPaymentByBigTable-shortLabel {
      flex: 1 1 auto;
      width: auto; } }

.AddPaymentByBigTable-AddPaymentCommission {
  position: relative;
  margin-bottom: 16px; }
  .AddPaymentByBigTable-AddPaymentCommission .ChangeDealerCommissions-wrapp .GridDropdown-content {
    height: 260px; }
  .AddPaymentByBigTable-AddPaymentCommission .ChangeDealerCommissions-wrapp .GridDropdown-contentWrapper {
    top: -260px; }
  @media (max-width: 922px) {
    .AddPaymentByBigTable-AddPaymentCommission .ChangeDealerCommissions-wrapp .GridDropdown-content {
      height: auto; } }

.AddPaymentByBigTable-overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8); }

.AddPaymentContainerFlex {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 922px) {
    .AddPaymentContainerFlex {
      flex-direction: column;
      align-items: flex-end; } }

.AddPaymentWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%; }
  @media (max-width: 1375px) {
    .AddPaymentWrapper {
      flex-direction: column; }
      .AddPaymentWrapper .flex-col {
        width: 100% !important; } }
  .AddPaymentWrapper .flex-col {
    width: 50%; }
  .AddPaymentWrapper div {
    max-width: 1000px; }

.break-spaces .ant-card-head-title {
  white-space: break-spaces !important; }
