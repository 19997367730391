.CommissionDetails-commissionNameRow{
  margin-bottom: 64px;
}

.CommissionDetails-commissionItems{
  display: flex;
  flex-wrap: wrap;
}

.CommissionDetails-cardContent{
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: 0 !important;
}

.CommissionDetails-priorityField{
  display: flex;
  align-items: center;
}

.CommissionDetails-priorityLabel{
  text-align: right;
}

.CommissionDetails-priorityRangeLabel{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.CommissionDetails-activityInfoField{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
}

.CommissionDetails-activityInfoLabel{
  margin-left: 8px;
}

.CommissionDetails-commissionNameField{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.CommissionDetails-radioLabel{
  text-align: end;
}

.CommissionDetails-radioWrapper{
  display: flex;
  align-items: center;
}


.CommissionDetails-input{
  width: 100% !important;
  box-sizing: border-box !important;
}
