.operability-datepicker-wrap {
  .k-datepicker {
    width: 50%;
    max-width: 400px;
  }
}

@media (max-width: 767px) {
  .operability-datepicker-wrap { 
    .k-datepicker {
      width: 100%;
      margin-top: 10px;
    }
  }
}

@media (max-width: 430px) {
  .operability-datepicker-wrap { 
    .k-datepicker {
      max-width: 100%;
    }
  }
}