.ModalGroupServices{

  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  &-label{
    width: 150px;
  }
  &-input{
    position: relative;
    width: 100%;
    input{
      width: 100%;
    }
  }

  &-error{
    color: red;
    font-size: 14px;
  }

}
