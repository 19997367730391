.MenuElementsGrid{
  min-height: 100px;
  &-hidden{
    a.k-link{
      display: none!important;
    }
  }
}

.MenuElementsGrid--root{
  height: calc(100% - 42px) !important;
}

.MenuProfilesGrid-rowWithOutChild{
  .k-hierarchy-cell{
    visibility: hidden;
  }
}
.MenuElementsGrid--withHidedCellHeaders{
  .k-header{
    display: none !important;
  }

  .k-pager-wrap{
    display: none!important;
  }
}



.MenuElementsGrid-cellDropdown{
  & button {
    background-color: lightgray;
    color: black;
    border: none;

    &:focus{
      background-color: lightgray;
      color: black;
    }

    &:hover{
      background-color: lightgray;
      color: black;
    }
  }
}

.MenuElementsGrid-cellDropdownWithLink{
  & button {
    color: blue;

    &:focus{
      color: blue;
    }

    &:hover{
      color: blue;
    }
  }
}

.MenuElementsGrid-refresh{
  margin: 4px;
}
