
.GroupMembership-content{
  padding-top: 24px;
}


.GroupMembership-row{
  .k-grid{
    overflow-x: auto;
    max-height: calc(100vh - 214px);
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
}

.GroupMembership-grid {
  @media (max-width: 992px) {
    margin-top: 16px;
  }
}

.GroupMembership-headerRow{
  margin-bottom: 16px;
  padding: 0 16px;

  @media (max-width: 580px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.GroupMembership-btnOnSave{
  margin-left: auto !important;

  @media (max-width: 580px) {
    margin: initial !important;
  }
}


.GroupMembership-headerUserRow{
  flex-wrap: nowrap;
  width: 560px !important;

  .GroupMembership-userLabel {
    margin-right: 12px;
  }

  @media (max-width: 580px) {
    margin-bottom: 8px;
    flex-direction: column;
    width: 100% !important;
    justify-content: flex-start;
    align-items: flex-start;

    .GroupMembership-userLabel {
      margin-right: 0;
      margin-bottom: 4px;
      text-align: left;
    }
  }
}
