.PreviewApplicationGrid{
  &-toolbar{
    width: 100%;
    max-width: 725px;
  }
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media (max-width: 725px) {
      flex-wrap: wrap;
    }
  }
  &-col{
    width: 100%;
  }
  &-dropdown{
    width: 550px;
    @media (max-width: 565px) {
      width: 100%;
    }
  }
  &-label{
    width: 80px;
  }
  &-select{
    width: 550px;
    @media (max-width: 565px) {
      width: 100%;
    }
  }
}
