.AdditionalLimits-row{
  padding-right: 16px;
  margin-bottom: 8px;
}
.AdditionalLimits-btnGroup{
  display: flex;
  margin-bottom: 16px;
  button{
    margin: 0 8px 0 0;
  }
}
.AdditionalLimits-input{
  width: 100%!important;
}

.AdditionalLimits-label{
  width: 106px;
}
