.NonRiskServicesGrid-toolbar{
  @media(max-width: 420px){
    padding-top: 8px;
  }
}

.NonRiskServicesGrid-actionsPanel{
  margin-top: 10px;
}

.SetServicesDialog{
  width: 640px !important;

  .ant-modal-content{
    width: 640px;
  }

  @media(max-width: 650px){
    width: 100% !important;

    .ant-modal-content{
      width: 100%;
    }
  }
}

.SetServicesDialog-row{
  padding: 16px;
  display: flex;
  align-items: center;
}
