.sortable-item-img {
  max-height: 40px;
  max-width: 80%;
}
.sortable-item-sub-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  p {
    line-height: 1;
    margin-top: 3px;
    font-size: 12px;
    // color: #000000;
  }
}
.k-state-disabled {
  background: rgba(170,169,173,.4) !important;
  p {
    color: #000000;
  }
}
.super-user-abs-disabled {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(168,168,168,.7);
  line-height: 1;
}
