$zindex-dropdown: 1000 !default;
$zindex-fixed: 1030 !default;
// https://getbootstrap.com/docs/4.0/layout/grid/

$resp_sm: 576px  !default;
$resp_md: 767px !default;
$resp_lg: 991px !default;
$resp_xl: 1200px !default;
$resp_menu_height: 550px !default;

$basic-radius: 0.1rem !default;
$basic-offset: 0.6rem !default;

$blue:    #066ab7 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dd2121 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #3498db !default;
$grey:    #faf9f8 !default;
$white:   #ffffff !default;
$light_blue : #1890ff !default;

$accent: $blue; // rewrite for kendo!

// Assumes the browser default, typically `16px`
$font-size-base: 1rem !default; 
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$font-weight-light: 100 !default;
$font-weight-normal: 400 !default;
$font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;