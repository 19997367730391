@import "../../styles/config";

.page-contacts {
  display: flex;
  flex-direction: column;
  padding-top: $basic-offset;

  ul, p, h3 {
    margin: 0;
    margin-bottom: $basic-offset;
  }
  span, li, a , p {
    font-size: 16px;
  }
  
  a {
    color: $blue;
  
    &:link {
      text-decoration: none;
    }
  }
  
  i {
    line-height: 1.4;
    color: gray;
  }
  
  .k-icon {
    font-size: $basic-offset * 2;
    margin-right: $basic-offset / 2;
  }
  
  img {
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
    margin-right: $basic-offset / 2;
  }
  .ant-card {
    position: relative;
    margin: auto 25%;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    // padding: 1.25rem;
    // border-radius: .25rem;
    .ant-card-head {
      // border-bottom: none;
      .ant-card-head-title {
        font-size: 18px;
        text-transform: initial;
        white-space: normal;
      }
    }
    .ant-card-body {
      padding: 0 24px;
    }
    ul {
      list-style-type: disc;
    }
  }
  .ml-5 {
    margin-left: 5px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .p-bold {
    font-weight: bold;
  }
  .mt-10 {
    margin-top: 10px;
  }
}

@media (max-width: $resp_lg) {
  .page-contacts  {
    .ant-card {
      margin: auto 10%;
    }
  }
}

@media (max-width: 576px) {
  .page-contacts  {
    .ant-card {
      margin: auto 5px;
      padding: 0;
      .ant-card-head  {
        .ant-card-head-title {
          font-size: 14px;
        }
      }
      .ant-card-body, .ant-card-head {
        padding: 0 10px;
      }
    }
     span,li,a, p {
       font-size: 14px;
     }
  }
}