@import "../styles/config.scss";

.k-animation-container {
  outline: none !important;
  .k-columnmenu-item-wrapper {
    outline: none !important;
    .k-popup {
      outline: none !important;
    }
  }
}

.k-dialog-wrapper .k-window-content {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    z-index: inherit;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
@media (max-width: $resp_lg) {
  .k-button {
    min-height: 39px;
    padding: 9px 20px;
  }
}
