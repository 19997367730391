.SupplierProps{
  padding: 16px;

  .GridDropdown-contentWrapper{
    left: 0;
    top: -480px;
  }

  .ant-select{
    width: 100%;
  }
}

.SupplierProps-inputRow{
  display: flex;
  align-items: center;
  margin-bottom: 28px;


  @media (max-width: 920px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.SupplierProps-label{
  flex: 0 0 112px;
  box-sizing: border-box;
  text-align: left;

  @media (max-width: 920px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}

.SupplierProps-shortLabel{
  flex: 0 0 60px;

  @media (max-width: 920px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}

.SupplierProps-longLabel{
  flex: 0 0 140px;

  @media (max-width: 920px) {
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
}

.SupplierProps-input{
  flex: 1 1 auto;

  @media (max-width: 920px) {
    width: 100%;
  }
}

.SupplierProps-row{
  display: flex;
  align-items: flex-end;

  &>div{
    flex: 0 0 400px;

    &:first-of-type{
      margin-right: 32px;

      @media (max-width: 640px) {
        margin-right: 0;
      }
    }

    @media (max-width: 920px) {
      flex: 1 1 50%;
    }

    @media (max-width: 640px) {
      flex: 1 1 100%;
      width: 100%;
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
}

.SupplierProps-singleRow{
  width: 832px;

  .SupplierProps-input{
    max-width: 720px;
  }

  @media (max-width: 920px) {
    width: 100%;

    .SupplierProps-input{
      width: 100%;
    }
  }
}

.SupplierProps-beneficiaryGridRow{
  padding-left: 112px;
  margin: -16px 0 32px;

  tr{
    height: 20px;
  }

  &>div{
    height: 200px !important;
  }

  @media (max-width: 920px) {
    padding-left: 0 !important;
  }
}

.SupplierProps-treatiesGrid{
  height: 200px !important;
}
