.SearchSanctionsTerrorist-toolbar{
  padding: 24px 0 32px;
}
.SearchSanctionsTerrorist-searchBar{
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.SearchSanctionsTerrorist-input-invalid{
  border: 1px solid red;
}
.SearchSanctionsTerrorist-error{
  color: red;
}
