.ChangingProfileHistory-filedRow {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.ChangingProfileHistory-datePicker {
  width: 100% !important;
}

.ChangingProfileHistory-actionsCol{
  display: flex;
  justify-content: flex-end;
}

.ChangingProfileHistory-label {
  text-align: right;
}

@media (max-width: 992px) {
  .ChangingProfileHistory-toolbar{
      margin-top: 16px;
  }

  .ChangingProfileHistory-datePicker{
    padding-right: 24px;
  }
}
