.modal-change-profile-row {
  label.ant-radio-wrapper {
    display: flex;
    .ant-radio {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      span {
        margin-left: 5px;
      }
    }
  }
}