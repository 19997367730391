.AddServiceByBigTable{
  padding: 32px;

  .GridDropdown-contentWrapper{
    left: 0;
  }
}

.AddServiceByBigTable-card{
  max-width: 760px;
  margin-bottom: 32px;
  position: relative;

  @media (max-width: 800px) {
    max-width: initial;
  }
}

.AddServiceByBigTable-inputRow {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.AddServiceByBigTable-label {
  flex: 0 0 140px;
  width: 140px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 8px;

  @media (max-width: 800px) {
    flex: 1 1 auto;
    width: auto;
    margin-bottom: 4px;
  }
}

.AddServiceByBigTable-input{
  width: 100%
}

.AddServiceByBigTable-rowWithAction{
  display: flex;
  align-items: center;

  &>.AddServiceByBigTable-action{
    flex: 0 0 auto;
    margin-bottom: 32px;

    .AddServiceByBigTable-inputRow{
      margin-bottom: 0;
    }
  }

  &>.AddServiceByBigTable-inputRow {
    flex: 1 1 auto;
    margin-right: 32px;
  }

  @media (max-width: 800px) {
   align-items: flex-end;
  }
}

.AddServiceByBigTable-shortLabel{
  flex: 0 0 116px;
  width: 116px;

  @media (max-width: 800px) {
    flex: 1 1 auto;
    width: auto;
  }
};

.AddServiceByBigTable-addServiceCommission{
  position: relative;
  .ChangeDealerCommissions-wrapp{
    .GridDropdown-content{
      height: 260px;
    }

    .GridDropdown-contentWrapper{
      top: -260px;
    }

    @media (max-width: 922px) {
      .GridDropdown-content{
        height: auto;
      }
    }
  }

  margin-bottom: 16px;
}

.AddServiceByBigTable-overlay{
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,0.8);
}
