.UsersAccessTerminals-row{
  flex: 1 1 auto;
  margin-bottom: 16px;
}

.UsersAccessTerminals-userInfoRow{
  margin-bottom: 16px;

  input{
    margin-left: 4px;
  }
}

//.UsersAccessTerminals-userInfoRow{
//  flex-wrap: nowrap;
//  width: 560px !important;
//
//  .UsersAccessTerminals-userLabel {
//    margin-right: 12px;
//  }
//
//  @media (max-width: 992px) {
//    margin-top: 8px;
//  }
//
//  @media (max-width: 580px) {
//    margin-bottom: 8px;
//    flex-direction: column;
//    width: 100% !important;
//    justify-content: flex-start;
//    align-items: flex-start;
//
//    .UsersAccessTerminals-userLabel {
//      margin-right: 0;
//      margin-bottom: 4px;
//      text-align: left;
//    }
//  }
//}
