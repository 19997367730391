.OTPServicesGrid-checkboxCell{
  border: 1px solid #d9d9d9 !important;
  background-color: white !important;
}

.OTPServicesGrid-toolbar{
  margin: -4px;
  display: flex;
  flex-wrap: wrap;
}

.OTPServicesGrid-buttonWrapper{
  padding: 4px;

  button{
    margin: 0;
  }
}
