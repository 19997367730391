.ChoiceCommission{
  padding: 16px;
}

.ChoiceCommission-toolbar{
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.ChoiceCommission-dropdown{
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  &:last-child{
    margin-bottom: 0;
  }
}

.ChoiceCommission-label {
  text-align: right;
}

.ChoiceCommission-actions{
  display: flex;
  justify-content: flex-end;
}

.ChoiceCommission-button{
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .ChoiceCommission-actions{
    margin-top: 16px;
  }
}
