.page-period {
  width: 100%;
  max-width: 700px;
  position: relative;
  margin: 20px auto 0;
  .k-widget.k-datepicker {
    width: 100%;
    margin-left: 5px;
  }
  .row-nowrap {
    flex-wrap: nowrap;
  }
  .k-textbox {
    width: 100%;
    margin-left: 5px;
  }

  .sub-wrapper {
    margin-top: 20px;
    .ant-btn  {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .page-period {
    .main-row {
      margin-left: 15px;
      margin-right: 15px;
    }
    .k-widget.k-datepicker {
      &:last-child {
        // margin-top: 20px;
      }
    }
    .row-nowrap {
      margin-top: 20px;
      flex-wrap: wrap;
      p {
        margin-left: 5px;
        margin-bottom: 5px;
        text-align: right;
      }
    }
    .sub-wrapper {
      &.first-child {
        margin-top: 0;
      }
    }
  }
}