
.ant-modal-body {
  padding: 5px 12px;
}

.modal-base-filter {
  .k-columnmenu-item-wrapper {
    .k-columnmenu-item {
      &:first-child {
        display: none;
      }
    }
  }
}
