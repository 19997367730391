.CashDebt-toolbar {
  .k-button{
    margin: 0;
  }

  .popup-owners-grid-wrapper{
    width: 100%;
  }

  @media(max-width:992px){
    margin-top: 8px;
  }
}

.CashDebt-toolbarRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.CashDebt-datePicker {
  width: 160px !important;

  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.CashDebt-datePickersRow{
  display: flex;
  flex-wrap: wrap;
}

.CashDebt-error{
  color: red;
  margin-top: 4px;
}

.CashDebt-label{
  width: 80px
}

.CashDebt-datePickerRow{
  display: flex;
  align-items: center;
  margin-right: 48px;

  @media (max-width: 608px) {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.CashDebt-button{
  @media (max-width: 708px) {
    margin-top: 8px !important;
  }

  @media (max-width: 564px) {
    margin-top: 0;
  }
}

