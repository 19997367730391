.CommissionDetails-fieldRow{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.CommissionDetails-fieldLabel{
  padding-left: 16px;
  text-align: right;
}
