.FilterConstructorModal{
  display: flex;
  justify-content: center;


  .ant-modal-body{
    overflow: auto;
    padding: 16px;
  }

  .ant-modal-content{
    display: inline-block;
  }

  @media (max-width: 560px) {
    width: 100% !important;

    .ant-modal-content{
      display: block;
      width: 100%;
    }
  }
}

.FilterConstructorModal-card{
  display: inline-block;

  .ant-card-body{
    padding: 8px 8px !important;
    display: flex;
    align-items: center;

    &>div{
      margin-right: 8px;
    }
  }
}

.FilterConstructorModal-cardActionContent{
  .ant-card-body{
    &>div{
      margin-right: 0;
    }

    &>button{
      margin-left: 8px;
    }
  }
}

.FilterConstructorModal-select{
  width: 200px;
}

.FilterConstructorModal-input{
  max-width: 200px;

  .ant-input-number{
    width: 100%;
  }
}

.FilterConstructorModal-closeExpressionAction{
  padding: 0 8px;
  margin: 0;
}

.FilterConstructorModal-treeListWrapper{
  padding: 4px 8px;
}

.FilterConstructorModal-treeActionListItemWrapper {
  position: relative;
  padding: 4px 8px;
}

.FilterConstructorModal-treeActionListItemWrapperVerticalLink{
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  width: 1px;
  height: 100%;
  top: -4px;
  left: -1px;
}

.FilterConstructorModal-treeActionListItemWrapper:last-of-type>.FilterConstructorModal-treeActionListItemWrapperVerticalLink {
  height: 33px;
}

.FilterConstructorModal-treeActionListItemWrapper {
  position: relative;
  padding: 4px 8px;
}



.FilterConstructorModal-treeActionWrapperHorizontalLink {
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  width: 28px;
  height: 1px;
  top: 29px;
  left: -1px;
}

