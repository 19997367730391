.CellCheckBoxElements {
  .ant-checkbox-wrapper {
    font-size: 12px;
    font-family: Tahoma;
    color: #656565;
  }
}

.CellCheckBoxElements-value{
  margin-left: 8px;
}
