@charset "UTF-8";
.info {
  margin: 50px auto;
  text-align: center; }

.info a {
  text-decoration: none;
  color: #016993; }

.info a:hover {
  color: #ad8c02; }

.fa {
  color: #fd6969; }

/*  ===== Выдвигающаяся верхняя панель
*   =========================================
*   Базовый контейнер выдвижной панели */
.top-panel {
  width: 100%;
  height: 80px;
  padding: 0;
  transition: top 400ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  background: #39464e; }
  @media screen and (max-width: 769px) {
    .top-panel {
      height: 53px;
      margin-top: 15px; } }

/* Блок сообщения */
.message {
  color: #fff;
  font-weight: 300;
  position: relative;
  padding: 20px;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .message {
      padding: 10px; } }

/* Заголовок 1 уровня */
.message h1 {
  color: #fff; }

/* Заголовок 2 уровня */
.message h2 {
  color: #888; }

.message p {
  margin: 1em 0; }

.message a {
  text-decoration: none;
  color: #748b74; }

.message a:hover {
  color: #95984b; }

/* Переключатель панели */
label.btn {
  display: block;
  margin: 15px 5px 0 0;
  cursor: pointer;
  background: #2bbbad;
  width: 50px;
  border-radius: 0 0 5px 5px;
  padding: 8px 5px;
  color: #FFF;
  font-size: 12px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  transition: all 400ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

/* Переключатель при наведении */
label.btn:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

/* Стрелка переключателя вниз */
/* Активация панели */
/* Смещение контейнера контента страницы */
.open:checked ~ .container {
  margin-top: 300px; }

/* Цвет переключателя при клике */
.open:checked + label.btn {
  background: #dd6149; }

/* Стрелка переключателя вверх*/
/* Контейнер контента */
.container {
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  transition: margin 400ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  padding: 5em 3em; }

.specific-terminalId-filter--wrapper {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
