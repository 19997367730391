.DealerCommissionsHistoryGrid-toolbar{
  display: flex;
  align-items: center;

  @media (max-width: 760px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.DealerCommissionsHistoryGrid-selects{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px -10px;
  max-width: 1196px;

  @media (max-width: 1480px) {
    max-width: 630px;
  }
}

.DealerCommissionsHistoryGrid-dataFields{
  display: flex;
  margin: 8px -10px -4px;
}


.DealerCommissionsHistoryGrid-fieldRow{
  display: flex;
  align-items: center;
  padding: 4px 10px;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.DealerCommissionsHistoryGrid-label{
  width: 60px;

  @media (max-width: 1480px) {
    width: 120px;
  }

  @media (max-width: 760px) {
    margin-bottom: 4px;
  }
}

.DealerCommissionsHistoryGrid-select{
  width: 480px;

  @media (max-width: 520px) {
    width: 100%;
  }
}

.DealerCommissionsHistoryGrid-searchAction{
  flex: 0 0 auto;
  padding-left: 24px;

  @media (max-width: 760px) {
    padding-left: 0;
    padding-top: 12px;
  }
}

.DealerCommissionsHistoryGrid-error{
  color: red;
}

.UpdateDealerCommissionsHistoryStatusModal-listWrapper{
  ul{
    list-style: inside !important;

    li{
      margin-bottom: 8px;
    }
  }
}
