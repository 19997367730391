.CommunicationMonitoringPerHour-fieldRow{
  display: flex;
  min-width: 1000px;

}
.CommunicationMonitoringPerHour-fieldCol{
  min-width: 8px;
  height: 25px;
  margin: 1px;
  cursor: pointer;
}

.CommunicationMonitoringPerHour-container{
  padding: 25px 16px 0;
  overflow: auto;
}
.bg-green{
  background-color: green;
}
.bg-red{
  background-color: red;
}

.CommunicationMonitoringPerHour-terminalNumber{
  margin-bottom: 16px;
}
