.aboutStock--wrapper {
  position: relative;
  overflow-y: auto;
  // height: auto !important;
  height: 100%;
  // background: rgb(240, 242, 245);
  background: #ffffff;
  // padding: 30px;
}
.aboutStock--container {
  padding-left: 87px;
  padding-right: 87px;
}

$header-width: 100%;
.aboutStock--header {
  width: 100%;
  position: relative;
  background: url("./header.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 349px;
  padding-top: 50px;
}

.aboutStock--header-top {
  // display: flex;
  // align-items: center;
  display: flex;
  position: relative;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
  .aboutStock-logo {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 170px;
    object-fit: contain;
  }
  h2 {
    font-weight: 900;
    font-size: 38px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 0;
    text-align: center;
  }
}

.aboutStock--header-bottom {
  position: relative;
  margin-top: 40px;
  width: 100%;
  p,
  span {
    font-size: 25px;
    color: #ffffff;
    font-weight: normal;
    max-width: 526px;
    &.bold-span {
      font-weight: 900;
    }
  }
}

.aboutStock-section {
  margin-top: 20px;
}

.aboutStock-section-btn {
  margin-top: 63px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .download-btn {
    padding: 16px 40px;
    font-size: 16px;
    width: 281px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.aboutStock-section-terminals {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
}

.aboutStock-content {
  width: 100%;
  display: flex;
  .aboutStock-content-left,
  .aboutStock-content-right {
    width: 50%;
  }
}
.aboutStock-content-left {
  p {
    color: #000000;
    max-width: 526px;
    font-size: 18px;
    &.top-text {
      font-weight: 400;
    }
    span.bold {
      font-weight: 700;
    }
    &.bottom-text {
      margin-top: 55px;
    }
  }
}

.aboutStock-content-right {
  position: relative;
  margin-top: -180px;
  .block {
    background: #ffffff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    width: 100%;
    width: 525px;
    height: 350px;
    // border: 1px solid #000000;
    padding-left: 38px;
    padding-right: 61px;
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    p.block-header {
      margin-top: 30px;
      font-size: 18px;
      color: #000000;
      font-weight: 700;
    }
  }

  $sub-block-img-width: 12px;
  .sub-block {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &.first {
      margin-top: 35px;
    }
    p {
      margin-left: 35px;
      width: calc(100% - 12px);
      font-size: 14px;
      color: #000000;
      span.bold {
        font-weight: 700;
      }
    }
    .sub-block-img {
      width: $sub-block-img-width;
      height: $sub-block-img-width;
      background: #1890ff;
      border-radius: 50%;
    }
  }
}

@media (max-width: 1300px) {
  .aboutStock-content-right .block {
    margin-left: 40px;
  }
  .aboutStock--container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 1200px) {
  .aboutStock-content {
    flex-direction: column;
  }
  .aboutStock-content-right {
    margin-top: 30px;
  }
  .aboutStock-content {
    .aboutStock-content-left,
    .aboutStock-content-right {
      width: 100%;
    }
  }
  .aboutStock-content-left p {
    max-width: 100%;
  }
  .aboutStock-content-left p.bottom-text {
    margin-top: 40px;
  }
  .aboutStock-content-right .block {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: auto;
  }
  .aboutStock-content-right .sub-block p {
    width: 100%;
  }
  .aboutStock--header-top h2 {
    font-size: 32px;
  }
  .aboutStock--header-bottom {
    p, span {
      font-size: 22px;
    }
  } 
}

@media (max-width: 991px) {
  .aboutStock-section-terminals {
    display: none;
  }
  .aboutStock--header-top .aboutStock-logo {
    max-width: 120px;
  }
  .aboutStock--container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media(max-width: 767px) {
  .aboutStock--header-top {
    display: block;
    margin-top: 10px;
    .aboutStock-logo {
      position: relative;
    }
    // h2 {
    //   font-size: 25px;
    // }
  }
  .aboutStock-section-btn {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .aboutStock--header {
    padding-top: 10px;
  }
  .aboutStock--header-bottom {
    margin-top: 20px;
    p {
      max-width: 100%;
    }
    // p, span {
    //   font-size: 18px;
    // }
  }
  .aboutStock-content-right .block p.block-header {
    margin-top: 15px;
  }
}

@media (max-width: 450px) {
  .aboutStock--header-top h2 {
    font-size: 22px;
    margin-top: 5px;
  }
  .aboutStock--header-bottom {
    span, p {
      font-size: 18px;
    }
  }
  .aboutStock-section {
    margin-top: 0;
  }
  .aboutStock--header {
    height: 300px;
  }
  .aboutStock-content-left p {
    font-size: 16px;
    &.bottom-text {
      margin-top: 20px;
    }
  }
  .aboutStock-content-right {
    margin-top: 0;
  }
  .aboutStock-content-right .sub-block.first {
    margin-top: 20px;
  }
  .aboutStock-content-right .block {
    box-shadow: none;
  }
  .aboutStock-content-right .block p.block-header {
    font-size: 14px;
  }
  .aboutStock--header-top .aboutStock-logo {
    max-width: 80px;
  }
  .aboutStock-content-right .sub-block p {
    margin-left: 15px;
  }
}


// .aboutStock-h2 {
//   font-size: 50px;
//   color: #000000;
//   padding-left: 60px;
// }
// .aboutStock--content {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   max-width: 900px;
//   margin-top: 50px;
// }
// .aboutStock--left {
//   width: 100%;
//   min-width: 400px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding-right: 15px;
// }
// .aboutStock-img {
//   width: 282px;
//   height: 282px;
// }

// .aboutStock--right {
//   position: relative;
//   overflow-y: auto;
//   height: 100%;
//   max-height: 330px;
// }

// @media (max-width: 768px) {
//   .aboutStock-h2 {
//     font-size: 30px;
//     text-align: center;
//     padding-left: 0;
//   }
//   .aboutStock--content {
//     flex-direction: column;
//     margin-top: 25px;
//   }
//   .aboutStock--left {
//     width: 100%;
//     max-width: 100%;
//     min-width: 100%;
//     padding-right: 0;
//   }
//   .aboutStock--right {
//     margin-top: 25px;
//   }
// }
