.SuspendedFinancialTransactionsGridToolbar-wideInput {
  width: 260px;
}

.SuspendedFinancialTransactionsModal-caption {
  font-size: 16px;
  margin-bottom: 8px;
}

.SuspendedFinancialTransactionsModal-label {
  font-size: 16px;
  margin-bottom: 4px;
}

.PaymentSuspendedFinancialTransactionsGridToolbar-toolbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.SuspendedFinancialTransactionsGridToolbar-toolbarFields {
  display: flex;
  margin: 0 -16px;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 1080px;

  @media (max-width: 1400px) {
    width: 100%;
  }
}

.PaymentSuspendedFinancialTransactionsGridToolbar-searchAction {
  display: flex;
  justify-content: flex-end;
  margin-left: 40px;
  @media (max-width: 1400px) {
    padding: 8px 16px;

    margin-left: 0;
  }
}

.SuspendedFinancialTransactionsGridToolbar-toolbarDateFieldsItem {
  display: flex;
  flex-wrap: wrap;
  width: 596px;
}

.SuspendedFinancialTransactionsGridToolbar-toolbarFieldsItem {
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.SuspendedFinancialTransactionsGridToolbar-toolbarTopAlignedFieldsItem {
  padding: 8px 16px;
  display: flex;
  align-items: flex-start;
}

.SuspendedFinancialTransactionsGridToolbar-FieldsItem {
  padding: 8px 16px;
  display: flex;
  align-items: flex-start;
  width: 750px;
  @media (max-width: 1400px) {
    width: 100%;
  }
}

.SuspendedFinancialTransactionsGridToolbar-label {
  width: 96px;
}

.SuspendedFinancialTransactionsGridToolbar-alignTopLabel {
  width: 96px;
  padding-top: 8px;
}

.SuspendedFinancialTransactionsGridToolbar-datePicker {
  width: 170px !important;
}

.SuspendedFinancialTransactionsGridToolbar-datePickerError {
  color: red;
  font-size: 12px;
  //margin-top: -8px;
  padding: 0 16px 0;
  flex: 1 1 100%;
}

.SuspendedFinancialTransactionsGridToolbar-statusLabel {
  width: 120px;
}

.PaymentSuspendedFinancialTransactionsGridToolbar-status {
  width: 410px;

  .GridDropdown {
    position: relative;
  }

  @media (max-width: 510px) {
    .GridDropdown-title {
      width: 200px;
    }
  }
}

.SuspendedFinancialTransactionsGridToolbar-toolbarWideFieldsItem {
  flex: 0 0 100%;
}

.SuspendedFinancialTransactionsGridToolbar-RadioGroup {
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 16px;

  label {
    margin-right: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    span {
      &:last-child {
        padding-bottom: 4px;
        margin-left: 2px;
      }
    }
  }
}

.SuspendedFinancialTransactionsGridToolbar-select {
  width: 100%;
}

.SuspendedFinancialTransactionsGridToolbar-input {
  width: 170px;
}

.SuspendedFinancialTransactionsGridToolbar-ibanLabel {
  width: 56px;
}

.SuspendedFinancialTransactionsGridToolbar-actions {
  flex: 1 1 100%;
  margin-top: 16px;

  @media (max-width: 620px) {
    margin: 4px -4px -4px;

    .ant-btn {
      margin: 4px;
    }
  }
}

.UnlocksHistoryGrid-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 8px;
}

.CommentHistoryDialog-block {
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.CommentHistoryDialog-blockItem {
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.PaymentActionsGrid-input {
  width: 120px;
}

.PaymentActionsGrid-label {
  width: 100px;
}

.CommentHistoryDialog {
  .ant-modal-header {
    padding: 16px 42px 16px 24px;
  }
}

.SuspendedFinancialTransactionsGridToolbar-externalOutgoingPayments {
  width: 100%;
  padding: 10px;
}
