.StepServiceCreationClientCommissions-toolbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .StepServiceCreation-serviceIdRow{
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.StepServiceCreationClientCommissions-inputRow {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.StepServiceCreationClientCommissions-label {
  flex: 0 0 80px;
  width: 80px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 8px;

  @media (max-width: 800px) {
    flex: 1 1 auto;
    width: auto;
    margin-bottom: 4px;
  }
}

.StepServiceCreationClientCommissions-input{
  width: calc(100% - 80px);

  @media (max-width: 800px) {
    width: 100%;
  }
}

.StepServiceCreationClientCommissionsModal {
  top: 10px !important;
  width: 600px !important;

  @media (max-width: 800px) {
    width: 100%;
  }

  .GridDropdown-content{
    height: 420px;
  }

  .GridDropdown-contentWrapper{
    left: 0;
  }
}

.StepServiceCreationClientCommissions-action{
  margin-top: 16px;
}
