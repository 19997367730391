.newTerminal-input {
  width: 100%;
  max-width: 300px;
}
.dropdown-profiles-wrapper-create {
  margin-left: 10px;
}
.newTerminal--wrapper {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  overflow: auto;
  height: 100%;
  p {
    margin-bottom: 0;
  }
}
.mt-10 {
  margin-top: 10px;
}
.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.newTerminal--warning-text {
  margin-left: 10px;
}

.newTerminal-input {
  width: 100%;
  max-width: 300px;
}
.newTerminal--wrapper {
  // padding-left: 15px;
  // padding-right: 15px;
  // width: 100%;
  // max-width: 800px;
  // position: relative;
  // margin: 10px auto 0;
  p {
    margin-bottom: 0;
    font-size: 14px;
    &.form-text {
      min-width: 177px;
      text-align: right;
    }
  }
  .k-datepicker {
    width: 50%;
    margin-left: 10px;
  }
}
.mt-10 {
  margin-top: 10px;
}
.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.newTerminal--warning-text {
  margin-left: 10px;
}
.newTerminal--header-input {
  margin-left: 10px;
}
.newTerminal--btn-save {
  display: flex;
  align-self: end;
}
.newTerminal--header-row {
  justify-content: space-between;
}
.newTerminal--card-row {
  flex-wrap: nowrap;
}
// .newTerminal--card {
//   &.w-50 {
//     width: 50%;
//   }
// }

@media (max-width: 400px) {
  .dropdown-profiles-wrapper-create {
    margin-left: 0;
    width: 100%;
  }
  .newTerminal--wrapper {
    .k-datepicker {
      width: 100%;
      margin-left: 0;
    }
  }
  .newTerminal--card-row {
    flex-direction: column;
    p {
      font-size: 14px;
    }
    .ant-select {
      width: 100% !important;
      margin-left: 0;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .newTerminal--btn-save {
    margin-top: 10px;
  }
  .btn-add-toggler {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  .newTerminal--wrapper p.form-text {
    text-align: left;
    min-width: 100%;
  }
}

.NewTerminal-cardAddressRow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
}

.NewTerminal-inputAddressLabel{
  width: auto;
  flex: 1 0 auto;
  min-width: 64px;
  max-width: 177px;
  box-sizing: border-box;
  text-align: right;
}

.NewTerminal-addressInput{
  box-sizing: border-box;
  margin-right: 8px;
  margin-left: 10px;
}

.NewTerminal-inputAddressAction{
  @media (max-height: 1264px) {
    margin-top: 4px;
  }
}

.NewTerminal-addressAdditionalBlock {
  padding: 0 16px 10px;
}
