.gridAdminStockPhotos-toolbar-row {
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  width: 100%;
  h2 {
    margin-bottom: 0;
  }
}

.modal-system-encashment--select {
  &.gridAdminStockPhotos-toolbar-photosFilter {
    &.results {
      margin-left: 0;
    }
    height: 40px;
    font-size: 14px !important;
    min-width: 298px;
    // padding: 12px;
    .ant-select-selection--single {
      height: 40px;
    }
    .ant-select-selection__rendered {
      line-height: 40px;
    }
  }
}

@media(max-width: 576px) {
  .gridAdminStockPhotos-toolbar-row {
    flex-direction: column;
    h2, div, button {
      width: 100%;
    }
    h2 {
      text-align: center;
      margin-top: 10px;
    }
    .popup-owners-grid-wrapper {
      margin-left: 0;
    }
  }
}