.AdditionalTransactionsPropsModal-row{
  display: flex;
  align-items: center;

  &>div:first-of-type{
    width: 90px;
    flex: 0 0 90px;
  }

  &>div:nth-of-type(2){
    flex: 1 1 auto;
  }
}
