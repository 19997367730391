.addSets-cell-value {
  min-width: 200px;
  max-width: 200px;
  text-overflow: clip;
  text-align: left;
  span {
    min-width: 120px;
    max-width: 120px;
    overflow: hidden;
  }
}

.tooltip-details {
  cursor: pointer;
  // background: #ffffff;
  // color: rgb(101,101,101);
}

.ant-tooltip-arrow:before {
  background-color: rgb(0,0,0);
}

.ant-tooltip-inner {
  // color: rgb(101,101,101);
  // background: #ffffff;
  background-color: rgb(0,0,0);
}