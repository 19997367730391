.BankDetails{
  padding: 32px;

  @media (min-width: 993px) {
    .GridDropdown-content{
      height: 396px;
    }
  }


  &-header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 900px;
    width: 100%;
    margin-bottom: 32px;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &--col{
      width: 100%;
    }
    &-wr--input{
      width: 100%;
      max-width: 500px;
    }
  }
    &-label{
      width: 160px;
      min-width: 160px;
      text-align: right;
      padding-right: 8px;
      &--small{
        width: 100px;
        padding-right: 8px;
        text-align: right;
      }

      @media (max-width: 992px) {
        text-align: left;
      }

    }
  &-formContent{
    max-width: 900px;
    width: 100%;

    &-input{
      width: 100%;
      max-width: 500px;
      &--sm{
        width: 150px;
        min-width: 150px;
      }

      @media (max-width: 992px) {
        max-width: 100%;
      }

    }
    &-select{
      width: 100%;
      max-width: 500px;

      @media (max-width: 992px) {
        max-width: 100%;
      }
    }
  }
  &-col{
    width: 50%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  &-row{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media (max-width: 992px) {
      flex-wrap: wrap;
      align-items: initial;
    }

    &--freeGrow{
      flex: 1 1 auto;

      @media (max-width: 992px) {
        width: 100%;
      }
    }

    &--noBottomGap{
      margin-bottom: 0;
    }

    &--colDirForTablet{
      @media (max-width: 992px) {
        flex-direction: column;
      }
    }
  }


  &-input{
    width: 100%!important;
    //margin-left: 5px;
    &--checkbox{
      display: block;
      width: 15px!important;
      height: 15px;
      margin-left: 8px;
    }
  }

  &-narrowGridDropdown{
    @media (min-width: 993px) {
      .GridDropdown-content{
        height: 300px;
      }
    }
  }

  &-gridDropdown{
    width: 100%;
    max-width: 500px;
    &--top{
      @media (min-width: 993px) {
        .GridDropdown-contentWrapper{
          top: auto;
          bottom: 101%;
        }
      }
  }
  }
  button{
    margin-left: 0!important;
  }
}

@media (max-width: 992px) {
  .BankDetails-formContent-input--sm{
    width: 100%;
  }
  .BankDetails-formContent-select--sm{
    width: 100%;
  }
  .BankDetails-label--small{
    text-align: left;
  }
  .BankDetails-gridDropdown{
    max-width: 100%;
  }
}
.no-wrap{
  flex-wrap: nowrap!important;
}
