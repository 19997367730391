.dashboard-wrapper {
  position: relative;
  overflow-y: auto;
  height: 100%;
  .statistic-iconer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-statistic-title {
      margin-bottom: 10px;
    }
    // .ant-statistic-content-prefix, .ant-statistic-content-value {
    //   font-size: 40px;
    // }
  }
}
.ant-statistic-title {
  margin-bottom: 0;
}

.ant-statistic-content-value-decimal {
  font-size: 24px;
}

.dashboard-statistic {
  transition: 0.3s ease-in;
  &:hover {
    background: #f6f6f6;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    // transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
}

@media (max-width: 767px) {
  .dashboard-wrapper {
    height: auto !important;
    .statistic-iconer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  .row-wrapper,
  .col-subwrapper {
    margin-top: 35px !important;
  }

  .ant-row {
    &:first-child {
      a {
        width: 100%;
        display: flex;
        align-items: center;
        .statistic-iconer {
          .ant-statistic-title {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .dashboard-link {
    color: rgba(0, 0, 0, 0.85);
    &:hover {
      text-decoration: underline;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

@media (max-width: 991px) {
  .dashboard-link {
    color: rgba(0, 0, 0, 0.85);
  }
}

@media (max-width: 576px) {
  .dashboard-wrapper {
    padding: 10px !important;
  }
}

.dashboard-select {
  width: 648px;
  margin-bottom: 10px;
}
