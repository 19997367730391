.ElementsMatrixGrid {
  padding: 20px;
  display: flex;
  height: calc(100% - 50px);
  justify-content: space-evenly;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  @media (max-width: 430px) {
    padding: 10px;
  }
}

.ElementsMatrixGrid-tags {
  height: 100%;
  width: 18%;

  @media (max-width: 767px) {
    height: 350px;
    width: 100%;
  }
}

.ElementsMatrixGrid-elements{
  height: 100%;
  width: 78%;

  @media (max-width: 767px) {
    height: 350px;
    width: 100%;
    margin-top: 20px;
  }
}

.ElementsGrid {
  height: 100% !important;
  width: 100%;

  @media (max-width: 767px) {
    &:last-child {
      margin-top: 20px;
    }
  }

  @media (max-width: 500px) {
    &:last-child {
      width: 100%;
    }
  }
}
