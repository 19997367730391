.ManagementPaymentTypes {
  &-toolbar{
    max-width: 750px;
    width: 100%;
  }

  &-row {
    display: flex;
    margin-bottom: 16px;
    &-aligent-center {
      align-items: center;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  &-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-label {
    width: 120px;
  }

  &-dateRow{
    display: flex;
    align-items: center;

    &:first-of-type{
      margin-right: 42px;
    }

    @media (max-width: 768px) {
      flex: 0 0 100%;
      width: 100%;

      &:first-of-type{
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    @media(max-width: 420px){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-shortLabel {
    width: 80px;
  }

  &-dropDown {
    width: 100%;
    .GridDropdown-title {
      width: 520px;
      margin-left: 0;
    }
    @media (max-width: 758px) {
      width: 100%;
      .GridDropdown-title{
        min-width: 100%;
        width: 100%;
      }
    }
  }

  &-btnGroup {
    button {
      margin-right: 8px;
    }
  }

  &-error{
    color: red;
  }

}
