.Questionnaire{
  margin: 0 16px;

  .ant-card-body{
    display: flex;
    margin: 8px;
    flex-wrap: wrap;


  }

  @media (max-width: 640px) {
    margin: 0 4px;

    .ant-card-body{
      margin: 0;
      padding: 8px;
    }
  }
}

.Questionnaire-subCard{
  margin: 16px 0;
  margin-top: 16px !important;
  width: 100%;
}

.Questionnaire-row{
  display: flex;
  align-items: center;
  margin:  4px -16px;
  flex-wrap: wrap;
  width: 100%;
}

.Questionnaire-fieldRow{
  display: flex;
  padding: 4px 16px;
  align-items: center;
  flex: 0 1 50%;
  box-sizing: border-box;

  @media (max-width: 1320px) {
    flex: 0 1 800px;
  }
  @media (max-width: 640px) {
    flex: 1 1 100%;
    flex-direction: column;
  }
}

.Questionnaire-label{
  width: 160px;
  flex: 0 0 160px;
  box-sizing: border-box;
  padding-right: 4px;

  @media (max-width: 640px) {
    width: 100%;
    flex: 1 1 100%;
    margin-bottom: 4px;
  }
}

.Questionnaire-input{
  flex: 1 1 auto;

  .ant-select{
    width: 100%;
  }

  .GridDropdown-contentWrapper{
    top: -480px;
    left: 0;
  }

  @media (max-width: 640px) {
    width: 100%;
    flex: 1 1 100%;
  }
}

.Questionnaire-actions{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 8px 12px;
  box-sizing: border-box;

  &>div{
    padding: 4px;
  }
}


