.ElementProps-languageRow{
  display: flex;
  align-items: center;
  margin-top: 16px;

  .ant-select{
    width: 160px;
    margin-left: 8px;
  }
}

.ElementProps-valueRow{
  margin-top: 16px;
}

.ElementProps-checkBoxRow{
  display: flex;
  align-items: center;

  &>div{
    margin-right: 4px;
  }
}

.ElementProps-error{
  margin-top: 8px;
  color: red;
  text-align: center;
}
