.Exceptions-toolbar {
  flex: 1 1 auto;
  margin-top: 16px;
  @media (max-width: 992px) {
    margin-top: 8px;
  }
}




