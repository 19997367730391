.mon-cab {
  .mon-cab-row {
    margin-bottom: 20px;
  }
  .k-widget.k-datepicker {
    margin-left: 0;
  }
  // .btn-wrapper {
  //   padding: 0 10px;
  // }
}

.monitor-text-block {
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  padding: 0 10px;
}
