.FiscalizedTransactionsDaysReportGrid-toolbar{
  display: flex;
  align-items: center;

  .GridDropdown-contentWrapper{
    left: 0;
  }

  @media (max-width: 672px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.FiscalizedTransactionsDaysReportGrid-dataFields{
  display: flex;
  margin: -4px -16px;

  @media (max-width: 560px) {
    flex-direction: column;
  }
}

.FiscalizedTransactionsDaysReportGrid-fieldRow{
  display: flex;
  align-items: center;
  padding: 4px 16px;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.FiscalizedTransactionsDaysReportGrid-label{
  width: 60px;
}

.FiscalizedTransactionsDaysReportGrid-error{
  color: red;
}

.FiscalizedTransactionsDaysReportGrid-searchAction{
  flex: 0 0 auto;
  padding-left: 24px;

  @media (max-width: 672px) {
    padding-left: 0;
    margin-top: 16px;
  }
}
